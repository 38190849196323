import React from "react";
import Footer from "../../ReusableComponents/Footer/Footer";
import Header from "../../ReusableComponents/Header/Header";
import '../../Assests/Css/Profile.css';
import { gift } from "../../MediaConstants/Index";
import ProfileSideTab from "./ProfileSideTab";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { BookingHistoryApi } from "../../Redux/Auth/Auth_Action";
import { useState } from "react";

const BookingHistory = () => {
    const [view, setView] = useState();
    const [history, setHistory] = useState(null);
    const [open1, setOpen1] = React.useState(false);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.Auth);
    const handleClickOpen1 = () => {
        setOpen1(true);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };

    const viewModalItem = (item) => {
        handleClickOpen1();
        setView(item);
    }

    const getBookingHistoryFunc = () => {
        dispatch(BookingHistoryApi(selector?.token))
            .then((res) => {
                setHistory(res?.data?.response?.data)
                // console.log(res, 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
            })
    }

    // console.log(history, '///////////////////////////////////');

    useEffect(() => {
        getBookingHistoryFunc();
    }, [])

    return (
        <div className="Profile BookingHistory">
            <Header />
            <div className="Profile_content">
                <div className="container">
                    <Dialog
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="px-3" id="alert-dialog-title">
                            {"Booking Details"}
                        </DialogTitle>
                        <DialogContent>
                            <div className="wdth-view-40A">

                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <p>Date :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.booking_date}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Address :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.address}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Service :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.services.name}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Status :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.service_status}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Price :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>${view?.total_amount}</p>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-light bt-red" onClick={handleClose1}>Close</button>
                        </DialogActions>
                    </Dialog>
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileSideTab />
                        </div>
                        <div className="col-lg-9 pos-rel">
                            <p className="profile_head mt-0">History</p>
                            {
                                history
                                    ?
                                    <div className="table-responsive">
                                        <table className="table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col brdr-rgt">Date</th>
                                                    <th scope="col brdr-rgt">Address</th>
                                                    <th scope="col brdr-rgt">Service</th>
                                                    <th scope="col">Payment</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {history.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {item?.booking_date}
                                                            </td>
                                                            <td>
                                                                {item?.address}
                                                            </td>
                                                            <td>
                                                                {item?.services.name}
                                                            </td>
                                                            <td>
                                                                {item?.total_amount}
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <button
                                                                        onClick={() => { viewModalItem(item) }}
                                                                        className="btn btn-light bt-grn mb-2">View</button>
                                                                </div>
                                                                {/* <div>
                                                                    <button disabled className="btn btn-light bt-complete">
                                                                        Completed
                                                                    </button>
                                                                </div> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <p className="profile_head mt-0"><small>No Record Found</small></p>
                            }

                            <img src={gift} className="plant_img" alt="pot with plant" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BookingHistory;