import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { gift } from '../../MediaConstants/Index';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import Map from '../../ReusableComponents/Map/Map';
// import ProfileSideTab from './ProfileSideTab';
import { showLoader } from '../../Services/Loader';

const Trackbooking = () => {

    const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
    
    return (
        <div className="Profile TrackBooking">
            <Header />
            <div className="Profile_content mt-5">
                <div className="container">
                    <div className="row mb-5">
                        {/* <div className="col-lg-3">
                            <ProfileSideTab />
                        </div> */}
                        <div className="col-lg-12 pos-rel">
                            <p className="profile_head mt-0 mb-0">Workers Location</p>
                            <Map />
                            {/* <div style={{ height: "400px", width: "400px" }} id="map"></div> */}
                            {/* <p className='min_away_txt'>03 minutes away</p> */}
                            {/* <div className="google-map-code">
                                <iframe className='cntrl-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359294.213879213!2d-113.71754000532059!3d36.24708982631331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1656064522421!5m2!1sen!2s" height="350" width="100%" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                            </div> */}
                            {/* <img src={gift} className="plant_img" alt="pot with plant" /> */}

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Trackbooking;