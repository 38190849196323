import React, { useState } from "react";
import '../../Assests/Css/Footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useNavigate } from "react-router-dom";
import { showLoader } from "../../Services/Loader";
import { headers, NewsLetterApiUrl } from "../../Services/ApisHelper";
import axios from "axios";
import { toast } from 'react-toastify';

const Footer = () => {

    let navigate = useNavigate();
    const [sendMail, setSendMail] = useState("");
    const [checkPolicy, setCheckPolicy] = useState(false);

    const SubmitEmail = (e) => {
        function isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        }
        if (!isValidEmail(sendMail) || !sendMail) {
            toast.error("Invalid Email");
            // console.log('Email is invalid');
        } else {
            if (!checkPolicy) {
                toast.error("Agree to privacy policy first");
            }
            else {
                SubscribeEmail();
            }
        }
    }

    const SubscribeEmail = async () => {
        showLoader("flex");
        let datasend = {
            email: sendMail
        }
        try {
            const { data } = await axios.post(NewsLetterApiUrl, datasend, headers)
            // console.log(data);
            showLoader("none");
            setSendMail("");
            toast.success("You have successfully subscribed for newsletter!");

        } catch (e) {
            let message = typeof e.response !== "undefined" ? e.response.data.errors.email : e.message;
            message?.map((item) => {
                return toast.error(`${item}`);
            })
            console.log(message);
            showLoader("none");
            setSendMail("");
        }
    }

    return (
        <div className="Footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <p className="heading_footer mb-4">Subscribe To Our</p>
                        <p className="news-ltr-txt">Newsletter</p>
                        <input
                            required
                            value={sendMail}
                            onChange={(e) => { setSendMail(e.target.value) }}
                            type="text" className="footer-txt-field" placeholder="Your email address*" />
                        <div className="d-flex">
                            <input onChange={(e) => { setCheckPolicy(e.target.checked) }} type='checkbox' checked={checkPolicy} className="chckbx" />
                            <p className="txt mt-1">I have read and agree to the privacy policy</p>
                        </div>
                        <button onClick={SubmitEmail} className="btn btn-light bt-website-orange w-100">Subscribe</button>
                    </div>
                    <div className="offset-lg-1 col-lg-2 ">
                        <p className="heading_footer mt-res-footer-profile">{"Profile "}</p>
                        <p onClick={() => { navigate('/login') }} className="txt cursor_pointer mb-2">Login</p>
                        <p onClick={() => { navigate('/signup') }} className="txt cursor_pointer mb-2">Sign Up</p>
                        <p onClick={() => { navigate('/profile') }} className="txt cursor_pointer mb-2">Profile</p>
                        <p onClick={() => { navigate('/track-worker') }} className="txt cursor_pointer mb-2">Track Worker</p>
                        {/* <p onClick={() => { navigate('/trackbooking') }} className="txt cursor_pointer">Geo Location</p> */}
                    </div>
                    <div className="col-lg-2 ">
                        {/* Useful Links */}
                        <p className="heading_footer">{" Policies"}</p>
                        <p onClick={() => { navigate('/terms') }} className="txt cursor_pointer mb-2 mt-res-footer-profile"> Terms & Conditions</p>
                        <p onClick={() => { navigate('/privacy') }} className="txt cursor_pointer mb-2">Privacy Policy</p>
                        {/* <p onClick={() => { navigate('/career') }} className="txt cursor_pointer mb-2">Career</p> */}
                        <p onClick={() => { navigate('/cancel') }} className="txt cursor_pointer">Cancellation Policy</p>
                    </div>
                    <div className="col-lg-2">
                        {/* mt-4 pt-3 */}
                        <p className="heading_footer ">Follow Us On</p>
                        <a href={`${JSON?.parse(localStorage?.getItem("siteData"))?.instagram}`} className="d-flex text-white no_underline" target="_blank">
                            <InstagramIcon className="insta_icon mt-1" />   <p className="txt cursor_pointer mb-0">Maple Leaf </p>
                        </a>
                        {/* <FacebookIcon className="insta_icon" /> <TwitterIcon className="insta_icon" /> */}
                    </div>
                    <div className="col-lg-12 py-5 text-center pnc">
                        {/* <p className="lst-p"><span className="cursor_pointer mr-3" onClick={() => { navigate('/privacy') }}>Privacy & Cookies</span> <span className="cursor_pointer" onClick={() => { navigate('/terms') }}>Terms & Conditions</span></p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;