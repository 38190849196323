import React, { useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/Auth.css';
import { useDispatch } from 'react-redux';
import { changePasswordApi } from '../../Redux/Auth/Auth_Action';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
    const [otp, setOtp] = useState(null);
    const [createNewPass, setCreateNewPass] = useState(null);
    const [confNewPass, setConfNewPass] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ChangePass = (e) => {
        e.preventDefault();
        // console.log(otp, createNewPass, confNewPass);
        if (createNewPass !== confNewPass) {
            toast.error("Password and confirm password must be same")
        } else {
            let data = {
                otp_code: otp,
                new_password: createNewPass,
                confirm_password: confNewPass,
            }
            dispatch(changePasswordApi(data))
                .then((res) => {
                    toast.success("Password changed successfully");
                    setTimeout(() => {
                        navigate('/login');
                    }, 1500);
                    // console.log(res);
                })
                .catch((err) => {
                    console.log(err, 'errrrrrrrrrrrrrrrrrr');
                })
        }
    }

    return (
        <div className='Auth'>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='offset-lg-4 col-lg-4'>
                        <div className='Auth-box'>
                            <h1 className='auth-head mb-4'>CHANGE PASSWORD</h1>
                            {/* <p className='mb-4'> */}
                            {/* <small className='clr-gry '>
                                    We have sent you an OTP on your email. th******ew@mail.com
                                </small> */}
                            {/* </p> */}
                            <form onSubmit={ChangePass}>
                                <p className='entr-code'>Please enter opt code</p>
                                <input value={otp} onChange={(e) => { setOtp(e.target.value) }} type='text' className="inpt-field mb-1" />
                                <p className='entr-code mt-2'>Create New Password </p>
                                <input value={createNewPass} onChange={(e) => { setCreateNewPass(e.target.value) }} type='password' className="inpt-field mb-1" />
                                <p className='entr-code mt-2'>Confirm New Password </p>
                                <input value={confNewPass} onChange={(e) => { setConfNewPass(e.target.value) }} type='password' className="inpt-field mb-1" />
                                <div className='row'>
                                    <div className='col-lg-12 text-center'>
                                        <button className='btn btn-light bt-website-orange px-log mt-4'>Next </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Verification;