import AllRoutes from './Routes/AllRoutes';
import '../src/Assests/Css/Common.css';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessages from '../src/ReusableComponents/ToastMessage';
import { gapi } from 'gapi-script';

function App() {

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: "",
        scope: ""
      });
    };

    gapi.load('client:auth2', start);
  }, [])

  return (
    <div className='APP_JS'>
      <>
        <div id="loader-full" className="loader-full">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <ToastMessages />
      </>
      <>
        <AllRoutes />
      </>
    </div>
  );
}

export default App;
