import React, { useEffect } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import { ser1, ser2, ser3 } from '../../MediaConstants/Index';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import { AllServiceUrl, headers } from '../../Services/ApisHelper';
import axios from 'axios';
import { showLoader } from '../../Services/Loader';
import { useState } from 'react';

const Service = () => {

    let navigate = useNavigate();
    const [services, setServices] = useState();

    let arr = [
        {
            image: ser1,
            headings: "Regular House Clean",
            text: "Our expert cleaners can clean everything from one-bedroom apartments to one-canal homes. Choose how many hours you need a cleaner for based on your needs and your budget, and then book them for those hours. Our experts are available all day, so you can do whatever you want.",
            inner_page: {
                heading1: " In our Regular Cleaning service, we do the following things: ",
                innherHead1: "Laundry",
                innertxt: "Our experts wash your clothes by hand and hang them up to dry. ",
                heading2: "Last Touches",
                innertxt2: "As a final touch, our helpers will fluff the pillows, put the rugs in place, adjust the cushions, and empty all the dust bins.",
                points: [
                    {
                        li: "•	Dusting all surfaces, including tables, ornaments, furniture, mirrors, and wall decorations.",
                    },
                    {
                        li: "•	We sweep every floor, including the stairs and carpets, to eliminate dust. Our cleaners sweep under sofas and beds and vacuum under mattresses.",
                    },
                    {
                        li: "•	If there is a vacuum cleaner, our experts will vacuum all the carpets to eliminate any dust or crumbs that may have been gathered."
                    }, {
                        li: "•	They mop all the rooms and the kitchen marble, tile, and chip floors until they shine. "
                    }, {
                        li: "•	We make the kitchen clean by doing the dishes and cleaning the sink. We clean the basin, faucet, stove top, and kitchen cabinet doors. "
                    },
                    {
                        li: "•	We get the bathroom in order by cleaning the toilet, the sink basin, faucets, shelf, and mirror on the vanity and flushing the toilet."
                    }
                ]
            }
        },
        {
            image: ser2,
            headings: "Deep House Clean",
            text: `The professional helpers we send will help you clean greasy stovetops, dirty window panes, and dusty ceiling fans so that your house stays clean all year.
            The experts we send to help you will pay special attention to the hard-to-reach places in your home, so your house will be spotless in no time.`,
            inner_page: {
                heading1: " Benefits of Deep Cleaning ",
                innherHead1: "Laundry",
                innertxt: "Our experts wash your clothes by hand and hang them up to dry. ",
                // heading2: "Last Touches",
                // innertxt2: "As a final touch, our helpers will fluff the pillows, put the rugs in place, adjust the cushions, and empty all the dust bins.",
                points: [
                    {
                        li: "Focusing on even the smallest details is the key contrast between regular and deep cleaning. Deep cleaning is much more thorough. ",
                    },
                    {
                        li: "In addition to improving your home's overall sanitization and livability, this kind of cleaning is more comprehensive than other cleaning methods. We will clean every area in your home that requires manual labor and is accessible from a stool.",
                    },
                    {
                        li: "A thorough cleaning clears your property of stubborn debris, filth, soap scum, and other waste products. "
                    }, {
                        li: "Cleaning and disinfecting surfaces can help eliminate microorganisms that infect your family. Sanitizing surfaces can assist in keeping germs at bay. Removing dirt, dust, and other debris can help establish a healthy environment in your house. Additionally, it gives everything a clean, fresh appearance and scent."
                    }, {
                        li: "If you choose a deep cleaning for your entire property, it will typically take longer than a regular cleaning service. It requires much attention to detail, which adds to the workload."
                    },
                ]
            }
        },
        {
            image: ser3,
            headings: "Move In/Move Out Clean",
            text: "Our cleaning services can be made to fit the size of your home and the specifics of how you want it cleaned. We also give free estimates, so you'll know what to expect and won't have to worry about taxes or charges that you didn't expect. If you use our experts' and move-out cleaning services, you won't have to worry about anything else.",
            inner_page: {
                // heading1: " In our Regular Cleaning service, we do the following things: ",
                innherHead1: "Move In",
                innertxt: "Moving requires a considerable lot of work. Receiving the keys to your new house only to discover that it needs a complete cleaning before you can even consider moving in is the most frustrating experience. Your move-in cleaning and sanitation needs can be handled entirely by Maple, leaving you with a spotless space to keep your furniture and other possessions. We offer the following cleaning services for new homes:",
                innherHead2: "Move Out",

                innertxt2: "A home's lease or purchase agreement may stipulate that a comprehensive move-out cleaning is necessary. Securing your security deposit will likely be essential if you leave a rental property. Maple has your back if you want to feel confident that the job is done correctly. Our move-out cleaning services are reliable, and this is because:",
                points: [
                    {
                        li: "•	Thoroughly clean the interiors of cabinets and drawers",
                    },
                    {
                        li: "•	Countertops, sinks, bathtubs, showers, and other fixtures will all be cleaned and sanitized as part of this service.",
                    },
                    {
                        li: "•	Cleaning home appliances such as ranges, stoves, and microwaves."
                    }, {
                        li: "•	Cleaning every form of floorings, such as wood, vinyl, tile, and others, and vacuuming each floor."
                    }, {
                        li: "•	Specialized door and door frame cleaning."
                    },

                ],
                points2: [
                    {
                        li: "•	The property will be immaculate throughout",
                    },
                    {
                        li: "•	Our team will arrive on time and do the task as soon as feasible",
                    },
                    {
                        li: "•	We'll make use of durable, environmentally friendly cleaning agents."
                    }, {
                        li: "•	Cleaning every form of floorings, such as wood, vinyl, tile, and others, and vacuuming each floor."
                    }, {
                        li: "•	Our cleaners have completed a thorough training program and are fully insured."
                    },

                ]
            }
        },
    ]

    const getServicesApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(AllServiceUrl, headers);
            // console.log(data?.response?.data, 'zzzzzzzzzzzzzzzzzzzzzzzzz');
            setServices(data?.response?.data);
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getServicesApi();
        window.scroll(0, 0);
    }, []);

    return (
        <div className='blog'>
            <Header />
            <div className='main_blog_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 text-center'>
                            <p className="main_blog_head">Services</p>
                        </div>
                        <div className='col-lg-12 text-center'>
                            <div dangerouslySetInnerHTML={{ __html: services?.[1]?.[0]?.services_text }} className='txt-all'></div>
                        </div>
                        {services?.[0]?.filter((item) => {
                            if (item.servicestatus.name === "main") {
                                return item;
                            }
                        })?.map((content, index) => {
                            return (
                                <div data-aos-duration="1000" data-aos="fade-up" key={index} className='col-lg-4 my-4   '>
                                    <div className='crd-bxshd h-100'>
                                        <img className='w-100' src={content.thumbnail_image} />
                                        <div className='px-3 py-2 pb-5 pos-rel-btn-bx'>
                                            <p className='item_heading mt-2'>
                                                {content.name}
                                            </p>
                                            <p className='txt-all text_lineLimit mb-5'>
                                                {content.short_desc}
                                            </p>
                                            <button onClick={() => {
                                                navigate('/services-detail', { state: content })
                                            }} className="btn btn-light bt-website-orange bt-blk pos-abs-btz mb-2 mt-4">
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className='col-lg-12 my-5 text-center'>
                            <button className='btn btn-light bt-pagi'>1</button>
                            <button className='btn btn-light bt-pagi'>2</button>
                            <button className='btn btn-light bt-pagi'>{">"}</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Service;