import React from "react";
import Footer from "../../ReusableComponents/Footer/Footer";
import Header from "../../ReusableComponents/Header/Header";
import '../../Assests/Css/Profile.css';
import { gift } from "../../MediaConstants/Index";
import ProfileSideTab from "./ProfileSideTab";
import { useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CancelBookingApi, RescheduleBookingApi, UpcomingBookingApi } from "../../Redux/Auth/Auth_Action";
import { useState } from "react";
import { toast } from "react-toastify";

const Booking = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [state, setState] = useState(null);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [view, setView] = useState(null);
    const [reschedule, setReschulde] = useState({
        date: "",
        time: "",
    });
    const ReduxState = useSelector((state) => state?.Auth);

    const onRescheduleChange = (e) => {
        let { name, value } = e.target;
        setReschulde(({ ...reschedule, [name]: value }));
    }

    // view modal open
    const handleClickOpen = (item) => {
        setOpen(true);
        setView(item);
        // console.log(item, "view");
    };

    const handleClickOpen1 = (item) => {
        setOpen1(true);
        setView(item);
        // console.log(item, "resschedule");
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
        // console.log("cencel booking");
    };


    const CancelBooking = (item) => {
        setOpen2(true);
        setView(item);
        // console.log("booking canceled");
    }

    // view modal close
    const handleClose = () => {
        setOpen(false);
    };

    // reschedule booking
    const handleClose1 = () => {
        setOpen1(false);
    };

    // cancel booking
    const handleClose2 = () => {
        setOpen2(false);
    };

    // console.log(reschedule,'222222222222222222');

    // reschedule bookimng function
    const RescheduleBookingFunc = () => {
        let data = {
            date: reschedule.date,
            time: reschedule.time,
            booking_id: view.id,
        }
        dispatch(RescheduleBookingApi(ReduxState?.token, data))
            .then((res) => {
                // console.log(res, 'zzzzzzzzzzzzzzzzzzzzzzz');
                setOpen(false);
                UpcomingBookingFunc();
                toast.success("Booking has been rescheduled successfully");
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzzzzzz');
                setOpen(false);
            })
    }

    const UpcomingBookingFunc = () => {
        dispatch(UpcomingBookingApi(ReduxState?.token))
            .then((res) => {
                setState(res?.data?.response?.data);
                // console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzz", res?.data?.response?.data);
            })
            .catch((e) => {
                // e?.response?.data?.errors?.map((item)=>{
                //     return(
                //         toast.info(item.message)
                //     )
                // })
                console.log(e?.response, 'eeeeeeeeeeeeeeeeeeee');
            })
    }


    const CancelBookingFunction = () => {
        let data = view.id;
        dispatch(CancelBookingApi(ReduxState?.token, data))
            .then((res) => {
                // console.log(res, 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
                toast.success("Service has been cancel sucessfully");
                UpcomingBookingFunc();
                handleClose2();
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
                UpcomingBookingFunc();
                console.log(err?.response?.data?.errors,'zzzzzzzzzzzzzzzzzzzzzzzz');
                toast.error(err?.response?.data?.errors);
                // err?.response?.data?.errors?.map((item)=>{
                //     return(
                //         toast.info(item.message)
                //     )
                // })
                handleClose2();
            })
    }

    useEffect(() => {
        UpcomingBookingFunc();
    }, []);

    return (
        <div className="Profile Booking">
            <Header />
            <div className="Profile_content">
                <div className="container">

                    {/* Reschedule starts*/}
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="px-5" id="alert-dialog-title">
                            {"When Would You Like to Reschedule again"}
                        </DialogTitle>
                        <DialogContent>
                            <div className="row">
                                <div className="col-lg-6">
                                    <input
                                        value={reschedule.date}
                                        name="date"
                                        min={new Date().toISOString().split('T')[0]}
                                        onChange={onRescheduleChange}
                                        type="date" className="inpt-fld" />
                                </div>
                                <div className="col-lg-6">
                                    <input
                                        value={reschedule.time}
                                        name="time"
                                        onChange={onRescheduleChange}
                                        type="time" className="inpt-fld" />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-light bt-red" onClick={handleClose}>Cancel</button>
                            <button className="btn btn-light bt-blue" onClick={RescheduleBookingFunc} autoFocus>Book Now</button>
                        </DialogActions>
                    </Dialog>
                    {/* Reschedule ends*/}

                    {/* View starts*/}
                    <Dialog
                        open={open1}
                        onClose={handleClose1}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="px-3" id="alert-dialog-title">
                            {"Booking Details"}
                        </DialogTitle>
                        <DialogContent>
                            <div className="wdth-view-40A">

                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <p>Date :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.booking_date}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Address :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.address}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Service :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.services?.name}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Status :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>{view?.service_status}</p>
                                </div>
                                <div className="col-lg-3">
                                    <p>Price :</p>
                                </div>
                                <div className="col-lg-9">
                                    <p>${view?.total_amount}</p>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button className="btn btn-light bt-red" onClick={handleClose1}>Close</button>
                        </DialogActions>
                    </Dialog>
                    {/* View ends*/}


                    {/* Cancel starts*/}
                    <Dialog
                        open={open2}
                        onClose={handleClose2}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="px-5" id="alert-dialog-title">
                            {"Are you sure want to cancel booking ?"}
                        </DialogTitle>
                        <DialogActions>
                            <button className="btn btn-light bt-red" onClick={handleClose2}>Cancel</button>
                            <button className="btn btn-light bt-blue" onClick={CancelBookingFunction} autoFocus>Cancel Booking</button>
                        </DialogActions>
                    </Dialog>
                    {/* Cancel ends*/}

                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileSideTab />
                        </div>
                        <div className="col-lg-9 pos-rel">
                            <p className="profile_head mt-0">Upcoming Bookings</p>

                            {state
                                ?
                                <div className="table-responsive">
                                    <table className="table ">
                                        <thead>
                                            <tr>
                                                <th scope="col brdr-rgt">Date</th>
                                                <th scope="col brdr-rgt">Address</th>
                                                <th scope="col brdr-rgt">Service</th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {/* 27 April 2022 */}
                                                            {item?.booking_date}
                                                        </td>
                                                        <td className="clr-gry">
                                                            {/* Lorem ipsum dolor sit amet,
                                                     consectetur adipiscing elit.
                                                     Ut elit tellus. */}
                                                            {item?.address}
                                                        </td>
                                                        <td>
                                                            {item?.services?.name}
                                                        </td>
                                                        <td>
                                                            ${item?.total_amount}
                                                        </td>
                                                        <td>
                                                            {/* <p className="cursor_pointer mb-2" onClick={() => { navigate('/trackbooking') }}>Track My Booking</p> */}
                                                            <div className="d-flex">
                                                                <button
                                                                    onClick={() => { handleClickOpen1(item) }}
                                                                    className="btn btn-light bt-grn mr-2">View</button>
                                                                <button
                                                                    onClick={() => { handleClickOpen(item) }}
                                                                    className="btn btn-light bt-blue">Reschedule</button>
                                                                <button onClick={() => { CancelBooking(item) }} className="btn btn-light bt-red">Cancel</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <p className="profile_head mt-0"> <small>No Record Found</small></p>
                            }

                            <img src={gift} className="plant_img" alt="pot with plant" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Booking;