import React, { useState } from "react";
import Footer from "../../ReusableComponents/Footer/Footer";
import Header from "../../ReusableComponents/Header/Header";
import '../../Assests/Css/Profile.css';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
// import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
// import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { gift } from "../../MediaConstants/Index";
import ProfileSideTab from "./ProfileSideTab";
import ViewProfile from "./ViewProfile";
import EditProfile from "./EditProfile";

const Profile = () => {
    const [profileTab, setProfileTab] = useState(1);
    return (
        <div className="Profile">
            <Header />
            <div className="Profile_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileSideTab />
                        </div>
                        <div className="col-lg-9 pos-rel">
                            {(profileTab === 1)
                                ?
                                <ViewProfile changeProfileTab={setProfileTab} />
                                :
                                null}
                            {(profileTab === 2)
                                ?
                                <EditProfile changeProfileTab={setProfileTab} />
                                :
                                null}
                            <img src={gift} className="plant_img" alt="pot with plant" />
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Profile;