import React, { useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/MakeBooking.css';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import StripeForm from '../../ReusableComponents/Stripe/StripeForm';
import Autocomplete from "react-google-autocomplete";
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { CheckCouponCodeApi, MakeABookingApi, PostBookingApi } from '../../Redux/Auth/Auth_Action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CheckOut = () => {
    const selector = useSelector((state) => state?.Auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const new_date = new Date().toISOString().slice(0, 10);
    // All billing and  init
    const [makeBookingService, setMakeBookingService] = useState(null);
    const [comment, setComment] = useState();
    const [cardData, setCardData] = useState();
    const [agreeTerms, setAgreeTerms] = useState(false);
    // who are you fields init
    const [whoYouAre, setWhoYouAre] = useState({
        f_name: selector?.user?.first_name || null,
        l_name: selector?.user?.last_name || null,
        y_email: selector?.user?.email || null,
        phone: selector?.user?.email || null,
        address: selector?.user?.street || null,
    });
    // when to visit us fields init 
    const [visitDateAndTime, setVisitDateAndTime] = useState({
        date: JSON?.parse(localStorage?.getItem("maple_home_date")) || null,
        time: JSON?.parse(localStorage?.getItem("maple_home_time")) || null,
    });
    const ChangeWhoAreYou = (e) => {
        let { name, value } = e.target;
        setWhoYouAre({ ...whoYouAre, [name]: value });
    }
    const VisitTimeFunc = (e) => {
        let { name, value } = e.target;
        setVisitDateAndTime({ ...visitDateAndTime, [name]: value });
    }

    const PostBookingApiFunc = () => {

        if (!whoYouAre.f_name || !whoYouAre.l_name || !whoYouAre.y_email || !whoYouAre.phone || !whoYouAre.address || !visitDateAndTime.date || !visitDateAndTime.time || !agreeTerms || !comment) {
            toast.error("Please Fill All")
        } else {

            if (
                !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    whoYouAre.y_email
                )
            ) {
                toast.error("Invalid Email");
                return;
            } else {
                let sendData = {
                    service_id: selector?.firstSaveData?.service_id,
                    attribute_id: selector?.firstSaveData?.attribute_id,
                    service_extra: selector?.firstSaveData?.service_extra_id,
                    date: visitDateAndTime.date,
                    time: visitDateAndTime.time,
                    service_type_id: selector?.firstSaveData?.service_type_id,
                    discount_id: selector?.firstSaveData?.coupon_code,
                    stripe_token: cardData?.token?.id,
                    total_amount: JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.total,
                    first_name: whoYouAre.f_name,
                    last_name: whoYouAre.l_name,
                    email: whoYouAre.y_email,
                    phone: whoYouAre.phone,
                    address: whoYouAre.address,
                    comments: comment,
                    zip_code: JSON?.parse(localStorage?.getItem("zip_code_maple")),
                    sales_tax: JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.salestax,
                }
                dispatch(PostBookingApi(selector?.token, sendData))
                    .then((res) => {
                        // console.log(res);
                        toast.success("Service booked successfully");
                        navigate('/');
                    })
                    .catch((err) => {
                        console.log(err);
                        setCardData();
                        // toast.error("Service")
                    })
            }

        }

    }



    useEffect(() => {
        if (cardData?.token?.id) {
            toast.success("Card Added Sucessfully");
        }
    }, [cardData]);

    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);


    return (
        <div className='MakeBooking'>
            <Header />
            <div className='MakeBooking_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='first_heading text-center'>Checkout</p>
                        </div>
                        <div className='col-lg-7'>
                            <div className='row'>
                                <div className='col-lg-12 mt-5 reszz_checkout_top0'>
                                    <p className='labl'>Billing Information</p>
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input
                                        value={whoYouAre.f_name}
                                        name="f_name"
                                        onChange={ChangeWhoAreYou}
                                        placeholder='First name' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input
                                        value={whoYouAre.l_name}
                                        name="l_name"
                                        onChange={ChangeWhoAreYou}
                                        placeholder='Last name' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input
                                        value={whoYouAre.y_email}
                                        name="y_email"
                                        onChange={ChangeWhoAreYou}
                                        placeholder='Your email' type='email' className='inpt-fld' />
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input
                                        value={whoYouAre.phone}
                                        name="phone"
                                        onChange={ChangeWhoAreYou}
                                        placeholder='Phone' type='number' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <input
                                        value={whoYouAre.address}
                                        name="address"
                                        onChange={ChangeWhoAreYou}
                                        placeholder='Street Address' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <textarea
                                        style={{ resize: "none" }}
                                        value={comment}
                                        name="comment"
                                        onChange={(e) => { setComment(e.target.value) }}
                                        placeholder='Comment' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 mt-5'>
                                    <p className='labl'>WHEN WOULD YOU LIKE US TO COME</p>
                                </div>

                                <div className='col-lg-6 mt-2 mt-rezz-mt-2'>
                                    <input min={new_date} value={visitDateAndTime.date} onChange={VisitTimeFunc} name="date"
                                        className='slct-fld' type='date' />
                                </div>
                                <div className='col-lg-6 mt-2 mt-rezz-mt-2'>
                                    <input value={visitDateAndTime.time} onChange={VisitTimeFunc} name="time"
                                        className='slct-fld' type='time' />
                                </div>

                                {/* {
                                    oftenn.map((item, index) => {
                                        return (
                                            <div key={index} className='col-lg-3 mt-2 px-2 text-center'>
                                                <div className={(userinfo1.often.includes(item.value)) ? 'bg_orgn  pos-rel pad-sml' : 'bg_orgn_no pos-rel pad-sml'}>
                                                    <input onChange={handleChange2} value={item.value} name={item.name} type='checkbox' className='chk-bx' />
                                                    <span className=''>{item.value}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                } */}
                                <div className='col-lg-12 mt-5'>
                                    <p className='paymnt_head mb-0'>Payment method</p>
                                </div>

                                <div className='col-lg-12 my-3'>
                                    <p className='txt-all'>Booking will not be saved unless you have up-to-date <span className='crdt-crd'>Credit Card</span> in your Billing Info.</p>
                                </div>
                                <div className='col-lg-12 '>
                                    <input onChange={(e) => { setAgreeTerms(e.target.checked) }}
                                        checked={agreeTerms}
                                        type="checkbox" className='mt-1 mr-3' />
                                    <span className='txt-all'>I agree to the Terms of Service and Privacy Policy.</span>
                                </div>
                                <div className='col-lg-8 my-3'>
                                    <StripeForm cardDetails={setCardData} />
                                </div>
                                <div className='col-lg-12  mb-5'>
                                    <button disabled={!cardData?.token?.id} onClick={PostBookingApiFunc} className="btn btn-light bt-website-orange mr-5 book_ser_final">Book Service</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 pl-5 pl_res_checkout_0'>
                            <div className='booking_detials_box '>
                                <p className='booksmry'>Booking Summary</p>
                                <div className='row'>
                                    <div className='col-7'>
                                        {JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.service_summary?.map((item, index) => {
                                            return (
                                                <p className='txt-all mb-3 text-dark' key={index + 250}>
                                                    <b>{item?.name}</b>
                                                </p>
                                            )
                                        })}
                                        {JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.attributesdata?.map((item, index) => {
                                            return (
                                                <ul key={index + 150}>
                                                    <li className='txt-all lst-crcl mb-1'>
                                                        {item?.name} x {item?.quantity}
                                                    </li>
                                                </ul>
                                            )
                                        })}


                                        {/* <p className='txt-all pl-3' >1 Bedroom</p>
                                        <ul>
                                            <li className='txt-all lst-crcl mb-1'>
                                                1 Bedroom
                                            </li>
                                            <li className='txt-all lst-crcl mb-1'>
                                                1 Kitchen
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className='col-5 text-right pr-4'>
                                        {JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.service_summary?.map((item, index) => {
                                            return (
                                                <p className='txt-all mb-3 text-dark' key={index + 250}>
                                                    {item?.price}
                                                </p>
                                            )
                                        })}
                                        {JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.attributesdata?.map((item, index) => {
                                            return (
                                                <p className='txt-all mb-3 ' key={index + 450}>
                                                    {item?.price}
                                                </p>
                                            )
                                        })}

                                    </div>

                                    <div className='col-lg-12 '>
                                        <p className='Booking_TOT'>Booking Total Amount</p>
                                    </div>
                                    <div className='col-7'>
                                        <p className='txt-all pl-3 mb-0' >Sub Total</p>
                                        <p className='txt-all pl-3  mb-0' >Sales Tax</p>
                                        <p className='txt-all pl-3  mb-0' >Delivery Charges</p>
                                        <p className='txt-all pl-3  mb-0' >Discount Amount</p>
                                        {/* <p className='orng-lrg-txt pr-3'>Total </p> */}
                                    </div>
                                    <div className='col-5 text-right  pr-4'>
                                        <p className='txt-all mb-0' >{JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.sub_total}</p>
                                        <p className='txt-all mb-0' >{JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.salestax}</p>
                                        <p className='txt-all mb-0' >{JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.deliverycharges}</p>
                                        <p className='txt-all mb-0' >{JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.discountamount}</p>
                                    </div>
                                    <div className='col-lg-12 text-right'>
                                        <p className='orng-lrg-txt pr-3 d-flex mt-3 justify-space-between'><small className='mr-5 mt-1'>Total</small> ${JSON?.parse(localStorage?.getItem("checkout_details"))?.booking_summary?.total}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CheckOut;