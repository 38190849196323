import { AFTER_LOGIN, SAVE_TOKEN, UPDATE_PROFILE } from '../../Services/Types';

const initialState = {
    user: null,
    token: null,
    firstSaveData: null,
};

const Auth = (state = initialState, action) => {
    // console.log("auth reducer jaaaaaaaaaaaaaaaaaaaa", action.payload);
    switch (action.type) {

        // saving token tfor authentication     

        case "SAVE_TOKEN":
            // console.log("auth reducer jzzzzzzzzzzzzzzzzzzzzzzzz", action.payload);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.userInfo,
            }

        case AFTER_LOGIN:
            // console.log("from reducer auth reducer", action.payload);
            return {
                ...state,
                user: action.payload,
            }

        // deleting token for logout functionality'

        case "DEL_TOKEN":
            // console.log(state.user, ' state user from auth reducer del token');
            return {
                ...state,
                token: null,
                activePackage: null
                // user : action.userInfo.user, 
            }


        // saving update profile data
        case "UPDATE_PROFILE":
            return {
                ...state,
                user: action.payload.e,
            }

        case "SAVEFIRST_DATA":
            // console.log('SAVEFIRST_DATA', action.payload);
            return {
                ...state,
                firstSaveData: action.payload.e,
            }

        default: {
            // console.log("default working")
            return state
        }
    }
}

export default Auth;