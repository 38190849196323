import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gift } from '../../MediaConstants/Index';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ProfileSideTab from './ProfileSideTab';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetLoyalityPointsApi } from '../../Redux/Auth/Auth_Action';
import { useState } from 'react';

const CustomerLoyality = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loyality, setLoyality] = useState();
    const [percentage, setPercentage] = useState(0);
    const ReduxState = useSelector((state) => state.Auth);

    const GetLoyalityFunc = () => {
        dispatch(GetLoyalityPointsApi(ReduxState?.token))
            .then((res) => {
                // console.log(res?.data.response?.data, 'zzzzzzzzzzzzzzzzzzzzzzzz');
                setLoyality(res?.data.response?.data);
                let getPoints = parseFloat(res?.data?.response?.data?.[0]?.points);
                let TotalPoints = parseFloat(res?.data?.response?.data?.[1]?.[0]?.points);
                let percnt = (getPoints / TotalPoints) * 100;
                setPercentage(percnt);
                // ${parseInt(loyality?.[0]?.points/parseInt(loyality?.[1]?.[0]?.points))*100}
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzzzzzzz');
            })
    }

    // console.log(percentage, 'sexxxxxxxxxxxxxxxxxx')

    useEffect(() => {
        GetLoyalityFunc();
        window.scroll(0, 0);
    }, [])
    return (
        <div className="Profile Customer_loyality">
            <Header />
            <div className="Profile_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileSideTab />
                        </div>
                        <div className="col-lg-9 pos-rel pl-5 res-loylty-pad">
                            <p className="profile_head mt-0 mb-0">My Loyalty Points</p>
                            <div className='flx-crd'>
                                <div className='gry-crd'>
                                    <p className='mb-2'>Completed Bookings: {loyality?.[0]?.points}/{loyality?.[1]?.[0]?.points}</p>
                                    <div className='sldr-bxx mb-2'>
                                        <div style={{ width: `${parseInt(percentage)}%` }} className='wdth-orng'></div>
                                    </div>
                                    <span>
                                        <CardGiftcardIcon className="res_cstmrlylty_icon" />
                                        {parseInt(loyality?.[1]?.[0]?.points) - parseInt(loyality?.[0]?.points) < 1 ? 0 : parseInt(loyality?.[1]?.[0]?.points) - parseInt(loyality?.[0]?.points)} More to win Gold Membership
                                        </span>
                                </div>
                            </div>
                            {/* <p className="profile_head mt-5 mb-4"><small className='bldd'>Benefit of {loyality?.[1]?.[0]?.type}</small> </p> */}
                            <div dangerouslySetInnerHTML={{ __html: loyality?.[1]?.[0]?.description }}
                            ></div>
                            {/* <ul className="ul_pad_zero">
                                <li className="ul_tick_round_white">
                                    <span className="f-14 f-pop-med lii">
                                        - Faster Service
                                    </span>
                                </li>
                                <li className="ul_tick_round_white">
                                    <span className="f-14 f-pop-med lii">
                                        - Top Rated Team
                                    </span>
                                </li>
                                <li className="ul_tick_round_white">
                                    <span className="f-14 f-pop-med lii">
                                        - Many More
                                    </span>
                                </li>
                            </ul> */}
                            <img src={gift} className="plant_img" alt="pot with plant" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CustomerLoyality;