import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../../Assests/Css/Header.css';
import { logo } from "../../MediaConstants/Index";
import PersonIcon from '@mui/icons-material/Person';
import { showLoader } from "../../Services/Loader";
import { headers, SiteSetting } from "../../Services/ApisHelper";
import axios from "axios";
import { useSelector } from "react-redux";
import SwipHeader from './SwipHeader';

const Header = () => {

    const [state, setState] = useState(0);
    const [siteSetting, setSiteSetting] = useState(null);
    const [siteData, setSiteData] = useState(null);
    let navigate = useNavigate();
    const selector = useSelector((state) => state?.Auth);
    const getUserProfileRedux = useSelector((state) => state?.Auth);
    let headerLinks = [
        { name: "Account", link: "/profile" },
        { name: "About", link: "/about" },
        { name: "FAQs", link: "/faq" },
        { name: "Blog", link: "/blog" },
        { name: "Services", link: "/service" },
        { name: "Contact", link: "/contact" },
        { name: "Gift Card", link: "/gift" },
        { name: "Career", link: "/career" },
    ];
    let headerPages = [
        // { list: "Home", link: "/" },
        { list: "About", link: "/about" },
        { list: "FAQs", link: "/faq" },
        { list: "Blog", link: "/blog" },
        { list: "Services", link: "/service" },
        { list: "Contact", link: "/contact" },
        // { list: "Account", link: "/profile" },
        { list: "Gift Card", link: "/gift" },
        { list: "Career", link: "/career" },
    ]
    let headerLinks_props = { headerLinks: headerLinks, siteData: siteData }
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,ar,de,zh-TW,el', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT }, 'trans')
    }

    var duplicate_google_translate_counter = 0;

    const GoogleFunc = () => {
        if (duplicate_google_translate_counter === 0) {
            // console.log('if first condtn', duplicate_google_translate_counter);
            var addScript = document.createElement('script');
            addScript.setAttribute('src', 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
            duplicate_google_translate_counter = 1
            // console.log('if last condtn', duplicate_google_translate_counter);
        }
    }


    const getSiteSettings = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(SiteSetting, headers);
            // console.log(data.response.data[0], 'hhhhhhhhhhhhhhhhhhhhhhhhhhh');
            setState(data?.response?.data[0]);
            setSiteData(data?.response?.data[0])
            localStorage.setItem("siteData", JSON.stringify(data?.response?.data[0]));
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        //this stops google adding button multiple times
        getSiteSettings();
        GoogleFunc();
    }, []);


    useEffect(() => {
        if (localStorage.getItem("siteData")) {
            setSiteSetting(JSON.parse(localStorage?.getItem("siteData")));
        }
    }, []);


    // console.log(selector?.user?.userpoint?.points,'2222222222222222222222222222');

    return (
        <div className="Header">
            <div className="header-full">
                <div className="logo-white-div">
                    <img onClick={() => { navigate('/') }} src={state?.logo2_url} alt="logo of maple leaf" className="pl-5 pt-2 cursor_pointer header_logo_here" />
                </div>
                <div className="black-div">
                    <div className="links-pages">
                        {headerPages.map((item, index) => {
                            return (
                                <Link key={index} className="no_link" to={`${item.link}`}>{`${item.list}`}</Link>
                            )
                        })}
                        <>
                            <div id="trans"></div>
                        </>
                    </div>

                    <div className="book_btn_service_header">
                        <PersonIcon onClick={() => { navigate('/profile') }} className="mx-2 cursor_pointer" />
                        <button onClick={() => { navigate('/makebooking') }} className="btn btn-light bt-website-orange ">Book Service</button>
                    </div>
                </div>
                {selector.token
                    ?
                    <div onClick={() => { navigate('/customerloyality') }} className="loyality_points cursor_pointer">
                        <p className="pnt-rtng">{selector?.user?.userpoint?.points ? selector?.user?.userpoint?.points : 0}</p>
                        <p className="mb-0 pnt-lylty ">Loyality</p>
                        <p className="mb-0 pnt-lylty">Points</p>
                    </div>
                    :
                    null
                }
            </div>
            {/* responsive header starts here */}
            <div className='header-small'>
                <div className='bg-red-hdr'>
                    <div className="sml_hdr_mob_maple">
                        <Link to="/">
                            <img onClick={() => { navigate('/') }} className=' img_respon py-2 px-0' src={state?.logo2_url} alt='logo' />
                        </Link>
                    </div>

                    {/* <div className='d-flex flx_profile_img'> */}
                        <div className='icon_profile_sml_hdr'>
                            {/* {getUserProfileRedux?.} */}
                            {/* <Link to="/profile" className="navlink">
                                <PersonIcon className='clr_balluu ml-1' />
                            </Link> */}
                            <Link to="/makebooking" className="btn bt-book-instant-mob">Book Service</Link>
                            <SwipHeader headerLinks={headerLinks_props} />
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};

export default Header;