import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    // Navigate
} from "react-router-dom";
import About from '../Pages/About/About';
import ChangePassword from '../Pages/Auth/ChangePassword';
import Login from '../Pages/Auth/Login';
import Registration from '../Pages/Auth/Registration';
import Verification from '../Pages/Auth/Verification';
import Blog from '../Pages/Blog/Blogs';
import Carrer from '../Pages/Carrer/Carrer';
import Contact from '../Pages/Contact/Contact';
import Faq from '../Pages/Faq/Faq';
import Gift from '../Pages/Gift/Gift';
import Home from '../Pages/Home/Home';
import MakeBooking from '../Pages/MakeBooking/MakeBooking';
import NewBooking from '../Pages/MakeBooking/NewBooking';
import CancelPolicy from '../Pages/PolicyPages/CancelPolicy';
import Privacy from '../Pages/PolicyPages/Privacy';
import TermsAndCondition from '../Pages/PolicyPages/TermsAndCondition';
import Booking from '../Pages/Profile/Booking';
import BookingHistory from '../Pages/Profile/BookingHistroy';
import CustomerLoyality from '../Pages/Profile/CustomerLoyality';
import Profile from '../Pages/Profile/Profile';
import Trackbooking from '../Pages/Profile/Trackbooking';
import InnerBlog from '../Pages/Blog/InnerBlog';
import Service from '../Pages/Service/Service';
import LoginTwo from '../Pages/Auth/Login2';
import Registration2 from '../Pages/Auth/Registration2';
import FeedBack from '../Pages/Auth/Feedback';
import Referral from '../Pages/Profile/Referral';
import ServicesDetail from '../Pages/Service/services-detail';
import RequireAuth from './RequireAuth';
import ForgetPassword from '../Pages/Auth/ForgetPassword';
import CheckOut from '../Pages/MakeBooking/Checkout';

const AllRoutes = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/faq' element={<Faq />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/service' element={<Service />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/gift' element={<Gift />} />
                    <Route path='/makebooking' element={<MakeBooking />} />
                    <Route path='/terms' element={<TermsAndCondition />} />
                    <Route path='/cancel' element={<CancelPolicy />} />
                    <Route path='/privacy' element={<Privacy />} />
                    <Route path='/track-worker' element={<Trackbooking />} />
                    {/* <Route path='/login' element={<Login />} /> */}
                    {/* <Route path='/signup' element={<Registration />} /> */}
                    <Route path='/career' element={<Carrer />} />
                    <Route path='/inner-blog/:id' element={<InnerBlog />} />
                    <Route path='/services-detail' element={<ServicesDetail />} />
                    <Route path='/login' element={<LoginTwo />} />
                    <Route path='/signup' element={<Registration2 />} />
                    <Route path='/feedback' element={<FeedBack />} />
                    <Route path='/checkout' element={<CheckOut />} />

                    <Route path='/forget-password' element={<ForgetPassword />} />
                    <Route path="/changePassword" element={<ChangePassword />} />
                    <Route path='/verification' element={<Verification />} />

                    {/* protected routes */}

                    <Route path='/profile' element={
                        <RequireAuth>
                            <Profile />
                        </RequireAuth>
                    } />

                    <Route path="/referral"
                        element={
                            <RequireAuth>
                                <Referral />
                            </RequireAuth>
                        } />
                    <Route path="/booking"
                        element={
                            <RequireAuth>
                                <Booking />
                            </RequireAuth>
                        } />
                    <Route path="/bookinghistory"
                        element={
                            <RequireAuth>
                                <BookingHistory />
                            </RequireAuth>
                        } />
                    <Route path="/newbooking"
                        element={
                            <RequireAuth>
                                <NewBooking />
                            </RequireAuth>
                        } />
                    {/* <Route path="/trackbooking"
                        element={
                            <RequireAuth>
                                <Trackbooking />
                            </RequireAuth>
                        } /> */}
                    <Route path="/customerloyality"
                        element={
                            <RequireAuth>
                                <CustomerLoyality />
                            </RequireAuth>
                        } />
                    <Route path='*' element={<Home />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default AllRoutes;