import React, { useEffect, useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import StripeForm from '../../ReusableComponents/Stripe/StripeForm';
import AOS from "aos";
import { showLoader } from '../../Services/Loader';
import axios from 'axios';
import { CouponsCheckGiftUrl, CouponsCheckUrl, GetGiftCardAmountUrl, GetGiftCardBookingUrl, headers } from '../../Services/ApisHelper';
import { toast } from 'react-toastify';

const Gift = () => {
    const [state, setState] = useState({
        rec_email: undefined,
        rec_name: undefined,
        sen_name: undefined,
        amount: undefined,
        youremail: undefined,
        desc: undefined,
        discountcode: undefined,
        to: undefined,
        from: undefined,
    });
    const [coupon_id, setCoupon_id] = useState();
    const [amount, setAmout] = useState();
    const [agreeToPolicy, setAgreeToPolicy] = useState();
    const [cardData, setCardData] = useState();
    const [summaryDiscount, setSummaryDiscount] = useState(null);
    const [summaryTotalAmout, setSummaryTotalAmout] = useState(null);
    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    useEffect(() => {
        if (cardData?.token?.id) {
            toast.success("Card Added Sucessfully");
        }
    }, [cardData]);

    const GetAmountApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(GetGiftCardAmountUrl, headers);
            // console.log(data);
            setAmout(data?.response?.data);
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        GetAmountApi();
    }, []);

    const SubmitData = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!agreeToPolicy) {
            toast.error("Agree to our terms & policies")
        } else {
            GiftApiFunc();
        }
    }

    const GiftApiFunc = async () => {
        let sendData = {
            recipient_name: state.rec_name,
            recipient_email: state.rec_email,
            sender_name: state.sen_name,
            email: state.youremail,
            to: state.to,
            from: state.from,
            comments: state.desc,
            coupon_id: coupon_id,
            gift_id: state.amount,
            stripe_token: cardData?.token?.id,
        }

        if (cardData?.token?.id) {
            showLoader("flex");
            try {
                const { data } = await axios.post(GetGiftCardBookingUrl, sendData, headers);
                // setState
                // rec_email: undefined,
                // rec_name: undefined,
                // sen_name: undefined,
                // amount: undefined,
                // youremail: undefined,
                // desc: undefined,
                // discountcode: undefined,
                // to: undefined,
                // from: undefined,
                // setState({...state , rec_email: undefined , rec_name: undefined , sen_name: undefined , amount: undefined , youremail: undefined , desc: undefined , discountcode: undefined, to: undefined, from: undefined,});
                toast.success("Gift card send successfully");
                 setState({
                    rec_email: undefined,
                    rec_name: undefined,
                    sen_name: undefined,
                    amount: undefined,
                    youremail: undefined,
                    desc: undefined,
                    discountcode: undefined,
                    to: undefined,
                    from: undefined,
                })
                setTimeout(() => {
                    window.location.reload();
                }, 1800);
                // console.log(data, 'res');
                showLoader("none");
            } catch (e) {
                console.log(e);
                toast.error("something went wrong please add details again");
                setTimeout(() => {
                    window.location.reload();
                }, 1800);
                showLoader("none");
            }
        } else {
            toast.error("Add card details");
        }
    }

    const checkCoupons = async () => {
        if (!state.amount) {
            toast.error("Please select amount first");
        } else {
            if (state.discountcode) {
                showLoader("flex");
                let sendData = {
                    code: state.discountcode,
                    gift_id: state.amount,
                }
                try {
                    const { data } = await axios.post(CouponsCheckGiftUrl, sendData, headers);
                    toast.info(`${data.message}`);
                    setCoupon_id(data?.response?.data?.coupon[0]?.id);
                    setSummaryDiscount(data?.response?.data?.booking_summary?.discountamount);
                    setSummaryTotalAmout(data?.response?.data?.booking_summary?.totalamount)
                    // console.log(data?.response?.data[0]?.id,'poyon');
                    showLoader("none");
                } catch (e) {
                    console.log(e);
                    showLoader("none");
                }
            } else {
                toast.error("Add coupouns first")
            }
        }
    }

    // console.log(cardData);

    return (
        <div className='blog'>
            <Header />
            <div className='main_blog_content'>
                <div className='container'>
                    <div className='row'>
                        <div data-aos-duration="1000" data-aos="fade-left" className='col-lg-8'>
                            <form onSubmit={SubmitData}>
                                <div className='row'>
                                    <div className='col-lg-12 '>
                                        <p className="main_blog_head">Gift Card</p>
                                        <p className='txt-all'>Get a MAPLE Clean Gift Card</p>
                                    </div>
                                    <div className='col-lg-12 mt-3 px-1'>
                                        <input
                                            required
                                            name='rec_email'
                                            value={state.rec_email || ""}
                                            onChange={InputVal}
                                            placeholder='Recipient email'
                                            type='email' className='inpt-fld' />
                                    </div>
                                    {/* <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            required
                                            name='rec_name'
                                            value={state.rec_name || ""}
                                            onChange={InputVal}
                                            placeholder='Recipient name*' type='text' className='inpt-fld' />
                                    </div> */}
                                    {/* <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            required
                                            name='sen_name'
                                            value={state.sen_name || ""}
                                            onChange={InputVal}
                                            placeholder='Sender name*' type='text' className='inpt-fld' />
                                    </div> */}
                                    <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            required
                                            name='youremail'
                                            value={state.youremail || ""}
                                            onChange={InputVal}
                                            placeholder='Your email' type='email' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            required
                                            name='to'
                                            value={state.to || ""}
                                            onChange={InputVal}
                                            placeholder='To' type='text' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            required
                                            name='from'
                                            value={state.from || ""}
                                            onChange={InputVal}
                                            placeholder='From' type='text' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-6 mt-3 px-1'>
                                        <select className='slct-fld slct-fld2 layout_matchhhed' name='amount'
                                            required
                                            value={state.amount || ""}
                                            onChange={InputVal}>
                                            <option selected disabled value={""}>Enter Amount</option>
                                            {amount?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{`$${item.amount}`}</option>
                                                )
                                            })}
                                        </select>
                                        {/* <input
                                        name='amount'
                                        value={state.amount || ""}
                                        onChange={InputVal}
                                        placeholder='Amount*' type='number' className='inpt-fld' /> */}
                                    </div>
                                    <div className='col-lg-12 mt-3 px-1'>
                                        <textarea
                                            required
                                            name='desc'
                                            value={state.desc || ""}
                                            onChange={InputVal}
                                            rows={5} className='inpt-fld-txt-area' placeholder='Type your message here'></textarea>
                                    </div>
                                    <div className='col-lg-6 mt-3 px-1'>
                                        <input
                                            // required
                                            name='discountcode'
                                            value={state.discountcode || ""}
                                            onChange={InputVal}
                                            placeholder='Enter Discount Code' type='text' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-6 text-right mt-3 px-1'>
                                        <button onClick={checkCoupons} type="button" class="btn btn-light bt-website-orange py-3 mt-1">
                                            Apply coupons
                                        </button>
                                    </div>
                                    <div className='col-lg-12 px-1'>
                                        <p className='checkout'>Checkout</p>
                                        <div>
                                            <input onChange={(e) => { setAgreeToPolicy(e.target.checked) }} checked={agreeToPolicy} type='checkbox' className='mt-1 mr-2 mb-4' /> <span className='txt-all'>I agree to the Terms of Service and Privacy Policy.</span>
                                        </div>
                                        <StripeForm cardDetails={setCardData} />
                                    </div>
                                    <div className='col-lg-12 pl-1'>
                                        <button disabled={!cardData} type='submit' className="btn btn-light bt-website-orange mt-3 mb-4 py-3">Proceed To Gift</button>
                                    </div>

                                </div>

                            </form>
                        </div>
                        <div className='col-lg-4 pt-5 pos-rel mt-cuszz'>
                            <div className='card_gift mt-5'>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <b>Gift Card</b>
                                    </div>
                                    <div className='col-lg-7 text-right'>
                                        {
                                            (state.amount)
                                                ?
                                                <div>
                                                    {amount?.filter((item) => {
                                                        if (item.id == state.amount) {
                                                            return item.amount
                                                        }
                                                    }).map((item) => {
                                                        return (
                                                            <b>$ {item?.amount}</b>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <b>$0.00</b>
                                        }
                                        {
                                            (state.to)
                                                ?
                                                <p className='text-align-lft'>Dear <b>{state.to}</b></p>
                                                :
                                                <p >Dear <b>Recipient Name</b> </p>
                                        }
                                        <div className='messg_box'>
                                            <p>{state.desc}</p>
                                        </div>
                                        <p className='mt-4'><b>Code :</b> XXX-XXX-XXX</p>

                                        {/* <p>$0.00</p> */}
                                        {/* <p></p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='bill_Gifty_BC'>
                                <h4 className='text-dark'>Billing Summary</h4>
                                <div className='row'>
                                    <div className='col-6'>
                                        <p className='txt-all mb-2'>Gift card :</p>
                                    </div>
                                    <div className='col-6 text-right pr-4'>
                                        {
                                            (state?.amount)
                                                ?
                                                <p className='txt-all mb-1'>
                                                    {amount?.filter((item) => {
                                                        if (item.id == state.amount) {
                                                            return item.amount
                                                        }
                                                    }).map((item) => {
                                                        return (
                                                            <b>$ {item?.amount}</b>
                                                        )
                                                    })}
                                                </p>
                                                :
                                                <b>$0.00</b>
                                        }
                                    </div>
                                    <div className='col-6'>
                                        <p className='txt-all mb-1'>Discount :</p>
                                    </div>
                                    <div className='col-6 pr-4 text-right'>
                                        <p className='txt-all mb-1'>$ {summaryDiscount ? summaryDiscount : 0}
                                        </p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='txt-all'>Total Amount :</p>
                                    </div>
                                    <div className='col-6 pr-4 text-right'>
                                        <p className='txt-all'>
                                            <b>$ {summaryTotalAmout ? summaryTotalAmout : 0}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div data-aos-duration="1000" data-aos="fade-down" className='img_phool bhool'>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default Gift;