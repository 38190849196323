import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutApiDelToken } from '../../Redux/Auth/Auth_Action';

const ProfileSideTab = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ReduxToken = useSelector((state) => state?.Auth);

    const LogoutFunction = () => {
        dispatch(LogoutApiDelToken(ReduxToken?.token))
            .then((res) => {
                dispatch({
                    type: "DEL_TOKEN"
                })
                navigate('/');
                // console.log(res, 'errrrrrrrrrrrrrrrrrrrrrrr');
            })
            .catch((e) => {
                dispatch({
                    type: "DEL_TOKEN"
                })
                navigate('/');
                console.log(e, 'errrrrrrrrrrrrrrrrrrrrrrr');
            })
    }
    // onClick={() => { navigate('/') }}
    return (
        <div className='ProfileSideTab'>
            <div className="grey_porfile_div">
                <p className="my_acc_brdr"><span className="iconz"></span> My Account</p>

                <p onClick={() => { navigate('/profile') }} className="prof-p cursor_pointer">< PersonOutlineIcon className="iconz" />Profile</p>
                <p onClick={() => { navigate('/booking') }} className="prof-p cursor_pointer">< CalendarTodayOutlinedIcon className="iconz" />Upcoming Booking</p>
                <p onClick={() => { navigate('/bookinghistory') }} className="prof-p cursor_pointer">< AccessTimeOutlinedIcon className="iconz" />History</p>
                <p onClick={() => { navigate('/referral') }} className="prof-p cursor_pointer">< GroupAddOutlinedIcon className="iconz" />Referral</p>
                <p onClick={() => { navigate('/customerloyality') }} className="prof-p cursor_pointer"><StarBorderIcon className="iconz" />My Loyalty  Points</p>
                <div className="logout_div">
                    <p onClick={LogoutFunction} className="prof-p cursor_pointer">Logout</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileSideTab;