import React, { useEffect, useState } from 'react';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/InnerService.css';
import { inner2, inner3, prof_img_round } from '../../MediaConstants/Index';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Footer from '../../ReusableComponents/Footer/Footer';
import { useParams } from 'react-router-dom';
import { headers, site_url } from '../../Services/ApisHelper';
import { showLoader } from '../../Services/Loader';
import axios from "axios";
import AOS from "aos";
import {
    FacebookShareButton,
    InstapaperShareButton,
    TwitterShareButton,
} from "react-share";

const InnerBlog = () => {
    let paramData = useParams();
    const [state, setState] = useState();
    const [locationUrl, setLocationUrl] = useState(null);
    const InnerBlogApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(`${site_url}/blogs/show/${paramData.id}`, headers);
            setState(data.response.data);
            // console.log(data.response.data,'jjjjjjjjjjjjjjjjj');
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        InnerBlogApi();
        AOS.init();
        AOS.refresh();
        setLocationUrl(window.location.href);
    }, []);



    return (
        <div className='InnerService'>
            <div style={{ backgroundImage: `url("${state?.image}")` }} className='bg_img_div'>
                <Header />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        {/* <h1 data-aos-duration="1000" data-aos="fade-left" className='heading_one'>
                            Remote Work is the Future, but Should You Go Remote?
                        </h1> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 mt-5'>
                        <h1 data-aos-duration="1000" data-aos="fade-left" className='heading_one'>
                            {state?.title}
                        </h1>
                        {/* <p data-aos-duration="1000" data-aos="fade-right" className='txtz'>
                            Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam placerat tortor commodo lectus laoreet venenatis. Donec ultricies, metus vitae bibendum consequat, tortor neque euismod lectus
                        </p> */}
                    </div>
                    <div className='col-lg-7 mt-3'>
                        <div className='banda_detail '>
                            <div className='d-flex flx-col-respnsv'>
                                <img data-aos-duration="1000" data-aos="fade-right" className='pro_imgg set_round_inner' src={state?.author_image} />
                                <div className='ml-3 align-self-center ml-res-left-social-0'>
                                    <p className='pers_name'>{state?.author}</p>
                                    <p className='pub_det'>Updated on {state?.updated_at.slice(0, 10)}</p>
                                </div>
                            </div>
                            <div>
                                <p className='pub_det'>Share :
                                    <FacebookShareButton
                                        url={window.location.href}
                                        quote={`${state?.title}`}
                                        // hashtag={"#hashtag"}
                                        // description={`${state?.description}`}
                                        className="Demo__some-network__share-button"
                                    >
                                        <FacebookIcon className='social_ico mx-1' />
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        title={`${state?.title}`}
                                        url={`${locationUrl}`}
                                        hashtags={[`${state?.title}`]}
                                    ><TwitterIcon className='social_ico mx-1' /></TwitterShareButton>
                                    {/* <InstapaperShareButton
                                      title={`${state?.title}`}
                                      description={`${state?.title}`}
                                    ><InstagramIcon className='social_ico mx-1' /></InstapaperShareButton> */}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: state?.content }} className='col-lg-12 mt-5 mb-4'></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InnerBlog;