import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
const stripePromise = loadStripe(
  "pk_test_51LGMGbHy66FgldI6FGUpwfMM9JNtpJF5UY5c59PyyA6UwUylL0V1utTfVeX4RopkrIrjDS4FxgZDis42pgSShsL0002KpasdW5"
);
//

const CheckoutForm = (props) => {
  //   const { StepTwoContinue, handleNext, setCardToken, cardToken } = props;
  const [formData, setFormData] = useState({});
  const [payProcessing, setPayProcessing] = useState(false);
  //   const [error, setError] = useState(false);
  const [done, setDone] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [paybutton, setPayButton] = useState(true);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const payload = await stripe.createToken(cardElement);
    // setCardToken(payload?.token?.id);
    props.cardDetails(payload);
    elements.getElement(CardElement).clear();
    // console.log("check", payload);
    if (payload?.token?.id) {
      //   handleNext(event);
      return;
    } else {
        toast.error("Something went wrong please try again");
      return;
    }
    setPayProcessing(true);
    setDone(true);
    setPayProcessing(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement
          onChange={(e) => {
            if (e.complete) {
              setPayButton(false);
            } else {
              setPayButton(true);
            }
          }}
        />
        <br />

        <ul className="list-inline">
          <li>
            <button
              type="button"
              onClick={handleSubmit}
              style={{ background: "#E77960", color: "#fff", padding: "0.7rem 3rem", border: 0, borderRadius: 0, fontFamily: "" }}
              className="default-btn stripePay-Btn btn f-14 f-pop-med"
              disabled={!stripe || !elements || paybutton}
            // onClick={props.cardDetails}
            >
              Add card
            </button>
          </li>
        </ul>
      </form>
    </>
  );
};

const StripeForm = (props) => {
  const { cardDetails } = props;
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        cardDetails={props.cardDetails}
      />
    </Elements>
  );
};

export default StripeForm;