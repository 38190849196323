import React from 'react';
import { useEffect } from 'react';
import '../../Assests/Css/StaticContent.css';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';

const CancelPolicy = () => {
    
    useEffect(()=>{
        window.scroll(0,0);
    },[])

    return (
        <div className='TermsAndCondition'>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 text-center'>
                        <p className='TnC_heading'>Cancellation Policy</p>
                    </div>
                    <div className='col-lg-12 text-center'>
                    <div dangerouslySetInnerHTML={{ __html: JSON?.parse(localStorage?.getItem("siteData"))?.cancel_policy }} className='TnC_txt mb-rezz'></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CancelPolicy;