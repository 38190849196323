import React, { useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/Auth.css';
import { useDispatch } from 'react-redux';
import { ForgotPasswordApi } from '../../Redux/Auth/Auth_Action';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgetPassword = () => {
    const [state, setState] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const EnterEmail = (e) => {
        e.preventDefault();
        let data = {
            email: state
        }
        dispatch(ForgotPasswordApi(data))
            .then((res) => {
                // console.log(res, 'zzzzzzzzzzzzzzzzzzz');
                setTimeout(() => {
                    toast.success("Check your email to verify otp");
                    navigate('/verification');
                }, 2000);
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzz');
                toast.error("Email not found");
            })
    }

    return (
        <div className='Auth Forget_Password'>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='offset-lg-4 col-lg-4'>
                        <div className='Auth-box'>
                            <h1 className='auth-head mb-4'>Forget Password</h1>
                            {/* <p className='mb-4'>
                                <small className='clr-gry '>
                                    We sent you an OTP on your email.
                                </small>
                            </p> */}
                            <p className='entr-code'>Please enter email </p>
                            <form onSubmit={EnterEmail}>
                                <input value={state} onChange={(e) => { setState(e.target.value) }} required type='email' className="inpt-field mb-1" />
                                <div className='row'>
                                    <div className='col-lg-12 text-center'>
                                        <button className='btn btn-light bt-website-orange px-log mt-4'>Next </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ForgetPassword;