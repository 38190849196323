import { Password } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChangePasswordProfileApi, LogoutApiDelToken, UpdateProfileApi } from '../../Redux/Auth/Auth_Action';

const EditProfile = (props) => {
    const ReduxState = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [state, setState] = useState({
        first_name: ReduxState?.user?.first_name || "",
        last_name: ReduxState?.user?.last_name || "",
        phone_no: ReduxState?.user?.phone_no || "",
        zip_code: ReduxState?.user?.zip_code || "",
        street: ReduxState?.user?.street || "",
        state: ReduxState?.user?.state || "",
        city: ReduxState?.user?.city || "",
        country: ReduxState?.user?.country || "",
        address: ReduxState?.user?.address || "",
        user: ReduxState?.user?.username || "",
        email: ReduxState?.user?.email || "",
    });

    const [resetPass, setResetPass] = useState({
        current_password: null,
        new_password: null,
        confirm_password: null,
    });

    const onProfileChnage = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const onPasswordChange = (e) => {
        let { name, value } = e.target;
        setResetPass({ ...resetPass, [name]: value });
    }

    // change password
    const ChangePasswordFunction = (e) => {
        e.preventDefault();

        if (resetPass.new_password !== resetPass.confirm_password) {
            toast.error("new password and confirm new password not matched");
        } else if (resetPass.new_password.lenght < 8) {
            toast.error("Password limit must be 8 character");
        } else {
            let data = {
                current_password: resetPass.current_password,
                new_password: resetPass.new_password,
                confirm_password: resetPass.confirm_password,
            }
            dispatch(ChangePasswordProfileApi(data, ReduxState?.token))
                .then((res) => {
                    toast.success("Password has been changed successfully");
                    // console.log(res, "response from api change apsswords");
                    LogoutFunction();
                })
                .catch((err) => {
                    toast.error(`${err?.response?.data?.errors}`);
                    console.log(err?.response?.data?.errors, 'zzzzzzzzzzzzzzz');
                    // err?.response?.data?.errors.map((item) => {
                    //     return (
                    //         toast.error(item)
                    //     )
                    // }
                    // )
                })
        }
    }

    // del token to after change Password
    const LogoutFunction = () => {
        dispatch(LogoutApiDelToken(ReduxState?.token))
            .then((res) => {
                dispatch({
                    type: "DEL_TOKEN"
                })
                navigate('/login');
                // console.log(res, 'errrrrrrrrrrrrrrrrrrrrrrr');
            })
            .catch((e) => {
                dispatch({
                    type: "DEL_TOKEN"
                })
                navigate('/login');
                console.log(e, 'errrrrrrrrrrrrrrrrrrrrrrr');
            })
    }

    const EditUserProfile = (e) => {
        e.preventDefault();
        // console.log("change profile", state);
        let data = {
            first_name: state.first_name,
            last_name: state.last_name,
            phone_no: state.phone_no,
            zip_code: state.zip_code,
            street: state.street,
            state: state.state,
            city: state.city,
            country: state.country,
            address: state.address,
        }

        dispatch(UpdateProfileApi(data, ReduxState?.token))
            .then((res) => {
                // console.log(res?.response);
                props.changeProfileTab(1);
                toast.success("Profile updated successfully");
            }).catch((err) => {
                err?.response?.data?.errors.map((item) => {
                    return (
                        toast.error(item)
                    )
                }
                )
            })
    }

    // () => { props.changeProfileTab(1) }

    return (
        <div className="EditProfile">
            <div className="row">
                <div className="col-lg-6">
                    <p className="profile_head  mt-0">User Profile</p>
                    <p className="User_details">User details</p>
                </div>
                <div className="col-lg-6 text-right">
                    <p onClick={() => { props.changeProfileTab(1) }} className="edit">View Profile</p>
                </div>
            </div>
            <form onSubmit={EditUserProfile}>
                <div style={{ backgroundColor: "white", zIndex: "10" }} className="detail_box">
                    <div className="row">
                        <div className="col-lg-4">
                            <p className="p-head mt-4">First Name</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="first_name"
                                value={state.first_name || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.first_name || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Last Name</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="last_name"
                                value={state.last_name || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.last_name || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Country</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="country"
                                value={state.country || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.country || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">City</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="city"
                                value={state.city || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.city || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">State</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="state"
                                value={state.state || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.state || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Address</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="address"
                                value={state.address || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.address || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Zip Code</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="zip_code"
                                value={state.zip_code || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.zip_code || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Street</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="street"
                                value={state.street || ""}
                                onChange={onProfileChnage}
                                type="text"
                                className="inpt-fld my-1" placeholder={`${ReduxState?.user?.street || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">Contact Number</p>
                        </div>
                        <div className="col-lg-8">
                            <input
                                name="phone_no"
                                value={state.phone_no || ""}
                                onChange={onProfileChnage}
                                type="number" className="inpt-fld my-1" placeholder={`${ReduxState?.user.phone_no || ""}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">User Name</p>
                        </div>
                        <div className="col-lg-8">
                            <input type="text" disabled className="inpt-fld my-1" placeholder={`${ReduxState?.user?.username}`} />
                        </div>
                        <div className="col-lg-12 text-center">
                            <div className="bordr-btm-lightgrey">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <p className="p-head mt-4">E-mail</p>
                        </div>
                        <div className="col-lg-8">
                            <input disabled type="text" className="inpt-fld my-1" placeholder={`${ReduxState?.user?.email}`} />
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-lg-12 text-right">
                        <button type="submit" class="btn btn-light bt-website-orange ">Save</button>
                    </div>
                </div>
            </form>
            {/* zaam */}
            {(ReduxState?.user?.google_id || ReduxState?.user?.facebook_id)
                ?
                <div className="my-5"></div>
                :
                <form onSubmit={ChangePasswordFunction}>
                    <div style={{ backgroundColor: "white", zIndex: "10" }} className="detail_box mt-3">
                        <div className="row">
                            <div className="col-lg-4">
                                <p className="p-head mt-4">Current Password</p>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    value={resetPass.current_password}
                                    name="current_password"
                                    onChange={onPasswordChange}
                                    required type="password" className="inpt-fld my-1" placeholder="******" />
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="bordr-btm-lightgrey">

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <p className="p-head mt-4">New Password</p>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    value={resetPass.new_password}
                                    name="new_password"
                                    onChange={onPasswordChange}
                                    required type="password" className="inpt-fld my-1" placeholder="******" />
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="bordr-btm-lightgrey">

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <p className="p-head mt-4">Confirm Password</p>
                            </div>
                            <div className="col-lg-8">
                                <input
                                    value={resetPass.confirm_password}
                                    name="confirm_password"
                                    onChange={onPasswordChange}
                                    required type="password" className="inpt-fld my-1" placeholder="******" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 mb-5 pb-5">
                        <div className="col-lg-12 text-right">
                            <button type="submit" class="btn btn-light bt-website-orange ">Reset Password</button>
                        </div>
                    </div>
                </form>
            }
        </div>
    );
};

export default EditProfile;