import { React, useState, useEffect } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import { gift } from '../../MediaConstants/Index';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import axios from 'axios';
import { CareerStoreUrl, headers, JobsUrls } from '../../Services/ApisHelper';
import { showLoader } from '../../Services/Loader';
import { toast } from 'react-toastify';

const Carrer = () => {

    const [data, setData] = useState({
        name: null,
        email: null,
        job_id: null,
    });
    const [file, setFile] = useState(null);
    const [jobData, setjobData] = useState(null);

    const inputVal = (e) => {
        let { name, value } = e.target;
        setData({ ...data, [name]: value });
    }

    const UploadFileFunc = (e) => {
        if (e.target.files?.[0]?.name.slice(-3) === 'pdf') {
            setFile(e.target.files);
        } else {
            toast.error("Invalid file type");
        }

    }

    const GetJobsApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(JobsUrls, headers);
            setjobData(data?.response?.data);
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    const SubmitData = async (e) => {
        // console.log(data, 'data', file);
        e.preventDefault();
        if (!data.name || !data.email || !data.job_id || !file?.[0]) {
            toast.error("Please fill all fields");
        } else {
            showLoader("flex");
            let formData = new FormData();
            formData.append('name', data?.name);
            formData.append('email', data?.email);
            formData.append('file', file[0]);
            formData.append('job_id', data?.job_id);

            try {
                const { data } = await axios.post(CareerStoreUrl, formData, headers);
                // console.log(data);
                showLoader("none");
                toast.success(`${data?.message}`);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } catch (err) {
                let message = typeof err.response !== "undefined" ? err.response.data.errors : e.message;
                for (let key in message) {
                    toast.error(message[key]?.[0]);
                }
                // console.log(message, 'career error');
                showLoader("none");
            }
        }
    }


    useEffect(() => {
        GetJobsApi();
    }, []);

    return (
        <div className='blog career'>
            <Header />
            <div className='main_blog_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 px-1'>
                            <p className="main_blog_head mb-3 mt-5">Career</p>
                            <div dangerouslySetInnerHTML={{ __html: JSON?.parse(localStorage?.getItem("siteData"))?.carrertext }} className="txt-all"></div>
                        </div>
                        <div className='col-lg-4 mt-3'>
                            <form onSubmit={SubmitData}>
                                <div className='row'>
                                    <div className='col-lg-12 mt-1 px-1'>
                                        <p className='abt-hm'>ABOUT YOUR HOME</p>
                                    </div>
                                    <div className='col-lg-12 mt-1 px-1'>
                                        <input required onChange={inputVal} value={data.name} name="name" placeholder='Name' type='text' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-12 mt-2 px-1'>
                                        <input required onChange={inputVal} value={data.email} name="email" placeholder='Email' type='email' className='inpt-fld' />
                                    </div>
                                    <div className='col-lg-12 mt-2 px-1'>
                                        <select required onChange={inputVal} value={data.job_id} name="job_id" className='slct-fld'>
                                            <option selected disabled value={null}>Position applying for</option>
                                            {jobData?.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.title}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-lg-12 mt-3 mb-2 px-1 pos-rel'>
                                        <input required onChange={UploadFileFunc} className='upld' type='file' accept="application/pdf" />
                                        <span className='upld-orng mb-2'><AttachFileIcon className="cursor_pointer" />{(file?.[0]?.name) ? <>{file?.[0]?.name?.slice(0, 40)}</> : "Upload pdf file only"} </span>
                                    </div>
                                    <div className='col-lg-12 mt-4 px-1'>
                                        <button type="submit" className="btn btn-light bt-website-orange mb-many">Apply</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-lg-8 pos-rel'>
                            <img src={gift} className="w-100 pos-imgg imaaa" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Carrer;