import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Slider from 'react-slick/lib/slider';
import { test_img } from "../../MediaConstants/Index";
import { useState } from 'react';
import { headers, TestimonialUrl } from '../../Services/ApisHelper';
import { useEffect } from 'react';
import { showLoader } from '../../Services/Loader';
import axios from 'axios';

const TestimonialSlider = () => {

    const [data, setData] = useState(null);

    const GetTestimonailApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(TestimonialUrl, headers);
            setData(data?.response?.data);
            // console.log(data?.response?.data,'testimonialssssssssssssssssssssss');
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        GetTestimonailApi();
    }, [])

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                // className='Slider_top_custom_arrows-next'
                style={{ ...style, display: "none", background: "red", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }


    function SamplePrevArrow(props) {
        const { style, onClick } = props;
        return (
            <div
                // className="Slider_top_custom_arrows"
                style={{ ...style, display: "none", background: "green", height: "80px", width: "80px" }}
                onClick={onClick}
            />
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "black",
                    borderRadius: "10px",
                    color: "white",
                    padding: "10px"
                }}
            >
                <ul style={{ margin: "0px" }}> <span style={{ color: "white" }}>{dots}</span> </ul>
            </div>
        ),
    };

    return (
        <div>
            <Slider {...settings} className="slider_top_home">
                {[1, 2, 3].map((item, index) => {
                    return (
                        <div className="container" key={index}>
                            <div className='row'>
                                <div>
                                    <FontAwesomeIcon className='star_rtng' icon={faStar} />
                                    <FontAwesomeIcon className='star_rtng' icon={faStar} />
                                    <FontAwesomeIcon className='star_rtng' icon={faStar} />
                                    <FontAwesomeIcon className='star_rtng' icon={faStar} />
                                    <FontAwesomeIcon className='star_rtng' icon={faStar} />
                                </div>
                                <div className="mt-3 mb-5">
                                    <p className='txt-all'>
                                        These folks were excellent. They were swift in responding, easy to work with, and arrived on time. They were fast and extremely courteous, leaving our apartment sparkling clean. Highly recommend them.
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <img src={test_img} className="testimonial_img" alt="testimonail person" />
                                    <div className="ml-3">
                                        <p className="mb-0 mt-3 test-pers">Joseph G.</p>
                                        <p className="mb-0 txt-all clr-org ">Highly recommend them</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default TestimonialSlider;