import React from "react";
import Footer from "../../ReusableComponents/Footer/Footer";
import Header from "../../ReusableComponents/Header/Header";
import '../../Assests/Css/Profile.css';
import { gift } from "../../MediaConstants/Index";
import ProfileSideTab from "./ProfileSideTab";
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetReferralApi, SentReferalCodeApi } from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";

const Referral = () => {

    const [referalLink, setReferalLink] = useState("");
    const [emailSent, setEmailSent] = useState("");
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.Auth);

    const GetReferralFunc = () => {
        dispatch(GetReferralApi(selector?.token))
            .then((res) => {
                // console.log(res, 'zzzzzzzzzzzzzzzz');
                setReferalLink(res?.data?.response?.data?.[0]);
            })
            .catch((err) => {
                console.log(err, 'zzzzzzzzzzzzzzzzzzz');
            })
    }

    const saveTextFunc = () => {
        navigator.clipboard.writeText(referalLink);
        toast.success("text copied successfully")
    }

    const SendInvite = (e) => {
        e.preventDefault();
        // console.log(emailSent);
        let data = {
            code: referalLink,
            email: emailSent,
        }
        dispatch(SentReferalCodeApi(data, selector?.token))
            .then((res) => {
                // console.log(res);
                toast.success("Invitation has been sent sucessfully");
                setEmailSent("");
            })
            .catch((err) => {
                console.log(err, 'errrrrrrrrrrrrrrrr');
            })
    }

    useEffect(() => {
        GetReferralFunc();
    }, [])
    return (
        <div className="Profile BookingReferral">
            <Header />
            <div className="Profile_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ProfileSideTab />
                        </div>
                        <div className="col-lg-9 pos-rel">
                            <div className="row">
                                <div className="col-lg-9 px-5 px-refrl-res">
                                    <p className="profile_head mb-1 mt-0">Invite Your Friends</p>
                                    <p className="loremipsum ">Invite your friends via email</p>
                                    <form onSubmit={SendInvite}>
                                        <input
                                            value={emailSent}
                                            onChange={(e) => { setEmailSent(e.target.value) }}
                                            className="inpt-fld" type='text' placeholder="Write Email Address of your friend" />
                                        <button className="btn btn-light mt-3 bt-website-orange">Send Invite</button>
                                    </form>
                                    <p className="profile_head mt-5">Share the referral link</p>
                                    <div className="copy_text_div">
                                        <p className="mb-0 ">
                                            <span className="blu-clrrr ref_link">
                                                {referalLink?.slice(0, 45)}..
                                            </span>
                                            <span className="mt-res-refrl-txt">
                                                <ContentCopyTwoToneIcon className="ml-4 ml-0-res-refrl" />
                                                <span
                                                    onClick={saveTextFunc}
                                                    className="copy_link cursor_pointer">
                                                    Copy Link
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img src={gift} className="plant_img" alt="pot with plant" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Referral;