import React, { useEffect, useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import AOS from "aos";
import { showLoader } from '../../Services/Loader';
import axios from 'axios';
import { ContactUsUrl, headers } from '../../Services/ApisHelper';
import { toast } from 'react-toastify';

const Contact = () => {
    const [state, setState] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState(({ ...state, [name]: value }));
    }

    const SubmitData = async (e) => {
        e.preventDefault();
        // console.log(state, "Wokring here");
        showLoader("flex");
        let datasend = {
            name: state.name,
            email: state.email,
            subject: state.subject,
            message: state.message,
        }
        try {
            const { data } = await axios.post(ContactUsUrl, datasend, headers)
            // console.log(data);
            toast.success(data?.message);
            setState({
                name: "",
                email: "",
                subject: "",
                message: "",
            })
            showLoader("none");
        } catch (error) {
            console.log(error);
            showLoader("none");
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        window.scroll(0, 0);
    }, []);
    return (
        <div className='blog contact'>
            <Header />
            <div className='main_blog_content'>
                <div className='container'>
                    <div data-aos-duration="1000" data-aos="fade-left" className='row'>
                        <div className='ml-5 col-lg-12 mt-3 left_res_contact_0'>
                            <p className="main_blog_head font-size-head-cont">We'd Love To Hear From You.</p>
                        </div>
                        <div className='col-lg-12 ml-5 left_res_contact_0'>
                            <p className='txt-all'>
                                We understand that every residential and commercial situation has unique cleaning needs. Feel
                                free to message or call us with any specifics. We are licensed and insured, and offer competitive
                                rates.
                            </p>
                        </div>
                        <form onSubmit={SubmitData}>
                            <div className="row ml-5 left_res_contact_0">
                                <div className='col-lg-5 mt-3'>
                                    <p className='lbl-field'>What's your Full Name?  </p>
                                    <input required name='name' value={state.name} onChange={InputVal} placeholder='e.g. John Doe' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-7 mt-3'>
                                    <p className='lbl-field'>Your Email Address  </p>
                                    <input required name='email' value={state.email} onChange={InputVal} placeholder='e.g. someone@gmail.com' type='email' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <p className='lbl-field'>Subject  </p>
                                    <input required name='subject' value={state.subject} onChange={InputVal} placeholder='e.g. Hello' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <p className='lbl-field'>Your message </p>
                                    <textarea required name='message' value={state.message} onChange={InputVal} rows={5} className='inpt-fld-txt-area' placeholder='Type here.'></textarea>
                                </div>
                                <div className='col-lg-12'>
                                    <button type="submit" className="btn btn-light bt-website-orange mt-3 mb-4">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='flx_main_div'>
                    <div data-aos-duration="1000" data-aos="fade-left" className='prt_1'>

                    </div>
                    <div data-aos-duration="1000" data-aos="fade-up" className='prt_2 text-center'>
                        <p className='tlk-cap'>Talk To Us</p>
                        <p className='tlk mb-1'>Calls us : {JSON?.parse(localStorage?.getItem("siteData"))?.contact_phone}</p>
                        <p className='tlk mb-1'>Email Us: {JSON?.parse(localStorage?.getItem("siteData"))?.contact_email}</p>
                        <p className='tlk mb-1'><b>Starting at $90 for 3h</b></p>
                    </div>
                    <div data-aos-duration="1000" data-aos="fade-right" className='prt_3'>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;