import React from 'react';
import '../../Assests/Css/Auth.css';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { GetWorkersApi, PostFeedBackApi } from '../../Redux/Auth/Auth_Action';
import { Rating } from "react-simple-star-rating";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const FeedBack = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rating, setRating] = useState(0);
    const [state, setState] = useState({
        f_name: null,
        l_name: null,
        email: null,
        feedback: null,
    });

    const [nameWorker, setNameWorker] = useState(null);

    const [worker, setWorker] = useState();

    const GetFeedBackWorker = () => {
        dispatch(GetWorkersApi())
            .then((res) => {
                setWorker(res?.data?.response?.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const ChangeHandler = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    useEffect(() => {
        GetFeedBackWorker();
    }, []);

    const SubmitData = (e) => {
        e.preventDefault();
        // console.log(state, 'zzzzzzzzzzzzzzzzzzzzzz');

        if (!rating) {
            toast.error("Please fill rating");
            return
        }

        let sendData = {
            worker_id: nameWorker,
            first_name: state.f_name,
            last_name: state.l_name,
            email: state.email,
            ratings: rating,
            feedback: state.feedback,
        }

        dispatch(PostFeedBackApi(sendData))
            .then((res) => {
                toast.success("Feedback has been sent successfully");
                navigate('/');
            })
            .catch((err) => {
                console.log(err);
            })

    }

    const handleRating = (rate) => {
        // console.log("working from hanle", rate);
        setRating(rate);
        // setRating(rate);
    };


    return (
        <div className='Auth FEEDBK'>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='offset-lg-3 col-lg-6'>
                        <div className='Auth-box'>
                            <h1 className='auth-head-zz text-center mb-4'>Feedback</h1>
                            <h1 className='auth-head mb-4 px-5'>Complete your booking.</h1>
                            <form onSubmit={SubmitData}>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <input
                                            required
                                            value={state.f_name}
                                            name="f_name"
                                            onChange={ChangeHandler}
                                            type='text' placeholder='First Name' className="inpt-field brdr-rd-in" />
                                    </div>
                                    <div className='col-lg-6'>
                                        <input
                                            required
                                            value={state.l_name}
                                            name="l_name"
                                            onChange={ChangeHandler}
                                            type='text' placeholder='Last Name' className="inpt-field brdr-rd-in" />
                                    </div>
                                    <div className='col-lg-6'>
                                        <input
                                            required
                                            value={state.email}
                                            name="email"
                                            onChange={ChangeHandler}
                                            type='email' placeholder='Your email' className="inpt-field brdr-rd-in" />
                                    </div>
                                    <div className='col-lg-6'>
                                        {/* <input type='text' placeholder='Worker Name' className="inpt-field brdr-rd-in" /> */}
                                        <select required className="slct-fld" onChange={(e) => { setNameWorker(e.target.value) }} >
                                            <option disabled selected>Select Worker</option>
                                            {worker?.map((item) => {
                                                return (
                                                    <option value={item?.id} key={item?.id}>{item?.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-lg-12 mb-4'>
                                        {/* <div><StarIcon className="strzz yll" /><StarIcon className="strzz yll" /><StarIcon className="strzz" /><StarIcon className="strzz" /><StarIcon className="strzz" /></div> */}
                                        <Rating onClick={handleRating} ratingValue={rating} />
                                    </div>
                                    <div className='col-lg-12'>
                                        <textarea required value={state.feedback}
                                            name="feedback"
                                            onChange={ChangeHandler} rows={5} style={{ resize: "none" }} type='text' placeholder='Write Your Feedback' className="inpt-field brdr-rd-in mb-2"></textarea>
                                    </div>
                                    <div className='col-lg-12'>
                                        <button type='submit' className='btn btn-light bt-website-orange w-100 mt-2 '>Submit </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FeedBack;