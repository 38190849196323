import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const drawerBleeding = 1;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

function SwipHeader(props) {
    const { window } = props;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className='swip_header_sml'>
            <Root className='header-small'>
                <CssBaseline />
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            // height: `calc(45% - ${drawerBleeding}px)`,
                            height: `100%`,
                            overflow: 'visible',
                        },
                    }}
                />
                <DensityMediumIcon className='cursor_pointer' style={{ background: "#000", color: "white" }} onClick={toggleDrawer(true)} />
                {/* <Button >Open</Button> */}
                <SwipeableDrawer
                    container={container}
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <div className='content_control_swiphead_res_header'>
                        <div className='d-flex mt-5'>
                            <div className='flx-2 list-style-type-circle'>
                                {props?.headerLinks?.headerLinks?.map((item, index) => {
                                    return (
                                        <div key={index} className='px-3 py-2'>
                                            <Link className='clr-hom-sml-hdr' to={item.link}>
                                                {/* <item.icon className='f-30' /> */}
                                                <span className='mt-4 pt-4 pos-rel-top-four ms-2 '>{item.name}</span>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flx-1 flex-end-hdr-smll'>
                                <CloseIcon onClick={toggleDrawer(false)} className='close_btn_sml_hdr' />
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>
            </Root>
        </div>
    );
}

export default SwipHeader;