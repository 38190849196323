import { React, useEffect, useMemo, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF, Marker, InfoWindow } from '@react-google-maps/api';
import { showLoader } from '../../Services/Loader';
import { useDispatch } from 'react-redux';
import { GetAllWorkersApi } from '../../Redux/Auth/Auth_Action';

const Map = () => {
    const [state, setState] = useState(null);
    const dispatch = useDispatch();
    const GetAllWorkersFunc = () => {
        dispatch(GetAllWorkersApi())
            .then((res) => {
                setState(res?.data?.response?.data);
                // window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            })
    }
    useEffect(() => {
        GetAllWorkersFunc();

    }, []);

    // console.log(state);

    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyDUgQwYkR8qiwXZt2V-Hf4uIFTAxW4STxY" })
    if (!isLoaded) return <div>Loading ....</div>;
    return <Map_Func state={state}/>;
}

function Map_Func(props) {
    const [center, setCenter] = useState({ lat: 44, lng: -80 });
    return (
        <GoogleMap zoom={10} center={{ lat: props.state?.[0]?.lat , lng: props.state?.[0]?.lng }} mapContainerClassName="mapContainerMF">
            {props.state?.map((item, index) => {
                return (
                    <>
                    <Marker position={{lat:item?.lat , lng:item?.lng}} title={`${item?.name}`} />
                    </>
                )
            })}
        </GoogleMap>
    )
}

export default Map;