import React, { useEffect, useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import { blog1, blog2, blog3 } from '../../MediaConstants/Index';
import AOS from "aos";
import { useNavigate } from 'react-router-dom';
import { headers, BlogsApiUrl } from "../../Services/ApisHelper";
import { showLoader } from "../../Services/Loader";
import axios from "axios";

const Blog = () => {

    let navigate = useNavigate();

    const [state, setState] = useState(null);
    const [links, setLink] = useState(null);

    const BlogsApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(BlogsApiUrl, headers);
            // console.log(data.response.data.links,'zzzzzzzzzzzzzzz');
            setState(data.response.data.data);
            setLink(data.response.data.links)
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        BlogsApi();
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className='blog blggg'>
            <Header />
            <div className='main_blog_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className="main_blog_head">Blogs</p>
                        </div>
                        {state?.map((item) => {
                            return (
                                <div key={item.id} data-aos-duration="1000" data-aos="fade-up" className='col-lg-4 my-4'>
                                    <div className='crd-bxshd'>
                                        <img className='w-100' src={item?.image} />
                                        <div className='px-3 py-2 bloggg-cont'>
                                            <p className='item_heading mt-2'>
                                                {item?.title}
                                            </p>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: item?.content }}
                                                className='txt-all txt-dngrsss'></div>
                                            <button onClick={() => { navigate(`/inner-blog/${item?.id}`) }} className="btn btn-light bt-website-orange bt-blk mb-2 bt-bloggg">
                                                Read More
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='col-lg-12 my-5 text-center'>
                            {links?.map((item, index) => {
                                if (item.label === "&laquo; Previous") {
                                    item.label = "Previous";
                                    return (
                                        <button key={index + 100}
                                            // onClick={() => { BlogPagination(item.url) }}
                                            disabled={(item.url === null ? true : false)}
                                            className={item.active ? 'btn btn-light bt-pagi atcvv_btnz' : 'btn btn-light bt-pagi'}>
                                            {item.label}
                                        </button>
                                    )
                                } else if (item.label === "Next &raquo;") {
                                    item.label = "Next";
                                    return (
                                        <button key={index + 100}
                                            // onClick={() => { BlogPagination(item.url) }}
                                            disabled={(item.url === null ? true : false)}
                                            className={item.active ? 'btn btn-light bt-pagi atcvv_btnz' : 'btn btn-light bt-pagi'}>
                                            {item.label}
                                        </button>
                                    )
                                } else {
                                    return (
                                        <button key={index + 100}
                                            // onClick={() => { BlogPagination(item.url) }}
                                            disabled={(item.url === null ? true : false)}
                                            className={item.active ? 'btn btn-light bt-pagi atcvv_btnz' : 'btn btn-light bt-pagi'}>
                                            {item.label}
                                        </button>
                                    )
                                }
                            })}
                            {/* <button className=''>1</button>
                            <button className=''>2</button>
                            <button className=''>{">"}</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Blog;