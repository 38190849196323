import axios from "axios";
import { toast } from "react-toastify";
import { ErrorAction } from "../Errors/Action";
import {
    headers, responseError, Login, SignUpUrl, LoginUrl, LogoutUrl, headersWithToken, ForgotPasswordUrl, ChangePasswordUrl, ChangePasswordProfileUrl, UpdateProfileUrl, BookingHistoryUrl, BookingUpcomingUrl, GetReferralUrl, SentReferralUrl, LoyalityPointsUrl, RescheduleBookingUrl, site_url, CouponsCheckUrl, BookingNext, PostBookingUrl, GetWorkersUrl, PostFeedBackUrl, InfoAfterLoginUrl, CouponsCheckGiftUrl, GetAllWorkersUrl
} from "../../Services/ApisHelper";
import { showLoader } from "../../Services/Loader";
import { AFTER_LOGIN, SAVE_TOKEN, UPDATE_PROFILE } from '../../Services/Types';

export const saveAfterLoginInfo = (activePackage) => {
    // console.log('from auth action top area', activePackage);
    return {
        type: AFTER_LOGIN,
        payload: activePackage,
    }
}

export const saveInfo = (token, userInfo) => {
    // console.log("save token from action authzzzzzzzzzzzzzzzzzz", token, userInfo);
    return {
        type: "SAVE_TOKEN",
        payload: { token, userInfo }
    }
}

export const UpdateProfileData = (e) => {
    // console.log("update profile data auth action", data)
    return {
        type: UPDATE_PROFILE,
        payload: { e }
    }
}


export const setProfile = (e) => {
    return {
        type: "SET_PROFILE",
        payload: e
    }
}

export const SaveFirstFormData = (e) => {
    // console.log("update profile data auth action", e)
    return {
        type: "SAVEFIRST_DATA",
        payload: { e }
    }
}


export const SignupApi = (data) => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(SignUpUrl, data, headers);
            // console.log("response from signup", res);
            toast.success("Verify Your Email To Access your account");
            showLoader('none');
            myResolve(res);
        } catch (e) {
            showLoader('none');
            if (e.response.data.statusCode === 444) {
                toast.error("Invalid referal code");
            } else {
                let errorHandler = responseError(e);
                dispatch(ErrorAction(errorHandler));
            }
            myReject(e);
        }
    });
};


// singup api starts

export const LoginApi = (data) => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(LoginUrl, data, headers);
            // console.log(res.data.data.token, res.data.data.user, 'mmmmmmmmmmmmmmmmmmmmmmmmmmmm');
            dispatch(saveInfo(res.data.data.token, res.data.data.user))
            myResolve(res);
            showLoader('none');
        } catch (e) {
            showLoader('none');
            e?.response?.data?.errors?.map((item) => {
                return (
                    toast.error(item?.message)
                )
            });
            myReject(e);
        }
    })
};

// logout api 

export const LogoutApiDelToken = (token) => {
    // console.log("logout working from auth actions", token);

    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(LogoutUrl, headersWithToken(token));
            // console.log("logout working from auth actions inside apicall", res);
            myResolve(res);
        } catch (e) {
            console.log("error from auth error", e);
            myReject(e);
        }
    })
}

// forgot passwrod api

export const ForgotPasswordApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ForgotPasswordUrl, data, headers);
            // console.log("logout working from auth actions inside apicall", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            console.log("error from auth error", e);
            showLoader("none");
            myReject(e);
        }
    })
}

// change password

export const changePasswordApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            showLoader("none");
            let res = await axios.post(ChangePasswordUrl, data, headers);
            // console.log("logout working from auth actions inside apicall", res);
            myResolve(res);
        } catch (e) {
            showLoader("none");
            console.log("error from auth error", e);
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// changePassword from profile page

export const ChangePasswordProfileApi = (data, token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            showLoader("none");
            let res = await axios.post(ChangePasswordProfileUrl, data, headersWithToken(token));
            // console.log("logout working from auth actions inside apicall", res);
            myResolve(res);
        } catch (e) {
            showLoader("none");
            // console.log("error from auth error", e);
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// upddate profile page

export const UpdateProfileApi = (data, token) => {
    // console.log(data, 'zzzzzzzzzzzzzzzzzzzzzz');
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            showLoader("none");
            let res = await axios.post(UpdateProfileUrl, data, headersWithToken(token));
            dispatch(UpdateProfileData(res.data.response.data));
            myResolve(res);
        } catch (e) {
            showLoader("none");
            console.log("error from auth error", e);
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(e);
        }
    })
}

// upcoming booking api

export const UpcomingBookingApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(BookingUpcomingUrl, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// booking history api

export const BookingHistoryApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(BookingHistoryUrl, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// get referal code

export const GetReferralApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetReferralUrl, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// send referral code

export const SentReferalCodeApi = (data, token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(SentReferralUrl, data, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// loyality points


export const GetLoyalityPointsApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(LoyalityPointsUrl, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// reschedule booking api

export const RescheduleBookingApi = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(RescheduleBookingUrl, data, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// cancel booking api

export const CancelBookingApi = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(`${site_url}/booking/cancel?booking_id=${data}`, headersWithToken(token));
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// make booking api

export const MakeABookingApi = (token, data) => {
    showLoader("flex");
    // console.log(data,'555555555555555555555555555555555555');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(
                `${site_url}/booking/all?service_id=${data?.service_id}&attribute_id[]=${data?.attribute_id}&service_type_id=${data?.service_type_id}&service_exta_id[]=${data?.service_exta_id}&coupon_code=${data?.coupon_code}`,
                //&lat=${data?.lat} &lng=${data?.lng}
                token ? headersWithToken(token) : headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

//  booking next post

export const PostBookingInfo = (token, data) => {
    showLoader("flex");
    // console.log(data,'555555555555555555555555555555555555');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(BookingNext, data, token ? headersWithToken(token) : headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// check coupon code

export const CheckCouponCodeApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(CouponsCheckUrl, data, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// Post booking api

export const PostBookingApi = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(PostBookingUrl, data, token ? headersWithToken(token) : headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// get feedback employees

export const GetWorkersApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetWorkersUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// post feedback api
export const PostFeedBackApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(PostFeedBackUrl, data, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// info after login api
export const InfoAfterLoginApi = (token) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(InfoAfterLoginUrl, headersWithToken(token));
            // console.log("response from contact api", res);
            dispatch(saveAfterLoginInfo(res?.data?.response?.data))
            showLoader("none");
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// Gift Card coupon api check

export const CheckCouponCodeGiftApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(CouponsCheckGiftUrl, data, headers);
            // console.log("response from contact api", res);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// get all workers

export const GetAllWorkersApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetAllWorkersUrl, headers);
            showLoader("none");
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e)
        }
    })
}

// maple apis ends here