import React, { useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/MakeBooking.css';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { CheckCouponCodeApi, MakeABookingApi, PostBookingInfo, SaveFirstFormData } from '../../Redux/Auth/Auth_Action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

const MakeBooking = () => {
    const selector = useSelector((state) => state?.Auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // All billing and  init
    const [makeBookingService, setMakeBookingService] = useState(null);
    // CHOOSE SERVICE TYPE init
    const [chooseServiceType, setChooseServiceType] = useState(null);
    // howOften state init
    const [howOften, setHowOften] = useState(null);
    // about your home 
    const [aboutYourHome, setAboutYourHome] = useState([]);
    // ABOUT EXTRAS
    const [userinfo, setUserInfo] = useState({
        languages: [],
    });
    // discount coupon code
    const [discountCoupon, setDiscountCoupon] = useState(null);
    const [coupounId, setCouponId] = useState(null);
    // who are you fields init
    const [whoYouAre, setWhoYouAre] = useState({
        f_name: null,
        l_name: null,
        y_email: null,
        phone: null,
    });
    // address including google autofill init
    const [address, setAddress] = useState({
        lat: null,
        lng: null,
    })

    const [zipCode, setZipCode] = useState(null);

    // when to visit us fields init 
    const [visitDateAndTime, setVisitDateAndTime] = useState({
        date: null,
        time: null,
    });
    // set how often into local state
    const HowOftenTime = (e) => {
        setHowOften(e);
    }

    // set about your home
    const AboutYourHomeChange = (value, id) => {
        if (aboutYourHome.find(item => id)) {
            let checkVal = aboutYourHome.filter((item) => {
                return item.id !== id;
            })
            setAboutYourHome([...checkVal, { value, id }])
        } else {
            setAboutYourHome((preVal) => {
                return [...preVal, { value, id }]
            })
        }
    }


    const ChangeWhoAreYou = (e) => {
        let { name, value } = e.target;
        setWhoYouAre({ ...whoYouAre, [name]: value });
    }

    const VisitTimeFunc = (e) => {
        let { name, value } = e.target;
        setVisitDateAndTime({ ...visitDateAndTime, [name]: value });
    }

    const checkCouponFunc = () => {
        if (!discountCoupon) {
            toast.error("Enter coupon");
        } else {
            let data = {
                code: discountCoupon,
                service_type_id: howOften,
            }
            dispatch(CheckCouponCodeApi(data))
                .then((res) => {
                    toast.info(res?.data?.message);
                    setCouponId(res?.data?.response?.data[0]?.id);
                })
                .catch((err) => {
                    toast.error("Invalid coupon code");
                    console.log(err, '//////////////////////');
                })
        }
    }

    const handleChange = (e) => {
        const { value, checked } = e.target;
        const { languages } = userinfo;
        if (checked) {
            setUserInfo({
                languages: [...languages, parseInt(value)],
            });
        }
        else {
            setUserInfo({
                languages: languages.filter((e) => e !== parseInt(value)),
            });
        }
    };

    // initial api call 
    const MakeABookingApiFunc = () => {
        let dataSend = {
            service_id: "",
            attribute_id: "",
            service_type_id: "",
            service_exta_id: "",
            coupon_code: "",
            lat: "",
            lng: "",
        }
        dispatch(MakeABookingApi(selector?.token, dataSend))
            .then((res) => {
                setMakeBookingService(res?.data?.response?.data);
                setChooseServiceType(res?.data?.response?.data?.services[0]?.id);
                setHowOften(res?.data?.response?.data?.services_type[0]?.id);
            })
            .catch((err) => {
                console.log(err, 'errrrrrrrrrrrrrrrr');
            })
    }

    const MakeABookingApiAfterFunc = () => {
        let dataSend = {
            service_id: chooseServiceType,
            attribute_id: aboutYourHome ? aboutYourHome : null,
            service_type_id: howOften,
            service_exta_id: userinfo.languages,
            lat: address.lat,
            lng: address.lng,
            coupon_code: discountCoupon,
        }
        dispatch(MakeABookingApi(selector?.token, dataSend))
            .then((res) => {
                setMakeBookingService(res?.data?.response?.data);
            })
            .catch((err) => {
                console.log(err, 'errrrrrrrrrrrrrrrr');
            })
    }

    const ProceedToCheckOut = (e) => {
        e.preventDefault();
        if (!chooseServiceType || !howOften || !zipCode || !aboutYourHome?.[0]) {
            toast.error("Please fill all fields");
        } else if (!address.lat || !address.lng) {
            toast.error("Please select address from suggested options");
        } else {
            let attribute_val = aboutYourHome?.map((item) => {
                return parseInt(item?.value);
            })
            let dataSend = {
                service_id: chooseServiceType,
                attribute_id: attribute_val,
                service_type_id: howOften,
                service_exta_id: userinfo.languages,
                lat: address.lat,
                lng: address.lng,
                coupon_code: discountCoupon,
            }
            dispatch(PostBookingInfo(selector?.token, dataSend))
                .then((res) => {
                    // console.log("res", res);
                    dispatch(SaveFirstFormData(dataSend));
                    localStorage.setItem('zip_code_maple', JSON.stringify(zipCode));
                    localStorage.setItem('checkout_details', JSON.stringify(res?.data?.response?.data));
                    setTimeout(() => {
                        navigate("/checkout");
                    }, 0);
                })
                .catch((err) => {
                    console.log("errrrrrrrrrrrrrrr", err);
                })
        }
    }


    // google lat long
    const GoogleGetLatLong = (res) => {
        setAddress({ ...address, lat: res?.geometry?.location?.lat(), lng: res?.geometry?.location?.lng() });
        // console.log(res?.geometry?.location?.lat(), 'lat');
        // console.log(res?.geometry?.location?.lng(), 'long');
    }

    // init api call
    useEffect(() => {
        MakeABookingApiFunc();
    }, []);
    // after changes api call
    useEffect(() => {
        MakeABookingApiAfterFunc();
    }, [chooseServiceType]);


    return (
        <div className='MakeBooking'>
            <Header />
            <div className='MakeBooking_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='first_heading text-center'>Make A Booking</p>
                            <p className='subMain_heading '>Complete your booking</p>
                        </div>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <p className='labl mt-3 mb-2'>Choose Service Type</p>
                                    <select onChange={(e) => { setChooseServiceType(e.target.value) }} className='slct-fld'>
                                        {makeBookingService?.services.map((item) => {
                                            return (
                                                <option value={item?.id} key={item?.id + 1000}>{item?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='col-lg-12 mt-5'>
                                    <p className='labl'>ABOUT YOUR HOME</p>
                                </div>
                                {makeBookingService?.attributes?.map((item, index) => {
                                    if (item?.attributescharge?.[0]) {
                                        return (
                                            <div key={index + 5000} className='col-lg-6 my-2'>
                                                <select onChange={(e) => { AboutYourHomeChange(e.target.value, item.id) }} className='slct-fld'>
                                                    <option selected disabled value={null}>{item?.name}</option>
                                                    {item?.attributescharge.map((itemz, i) => {
                                                        return (
                                                            <option value={itemz?.id} key={i + 2400}>{item?.name}{" "}{itemz?.quantity}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                                {/* <div className='col-lg-6 my-2'>
                                    <select className='slct-fld'>
                                        <option>1 Bedroom</option>
                                        <option>2 Bedroom</option>
                                        <option>3 Bedroom</option>
                                    </select>
                                </div> */}
                                {makeBookingService?.servicesextra?.[0]
                                    ?
                                    <div className='col-lg-12 mt-5'>
                                        <p className='labl'>ABOUT EXTRAS</p>
                                        <div className="row">
                                            {makeBookingService?.servicesextra?.map((item, index) => {
                                                return (
                                                    <div key={index + 666} className='col-lg-3 mt-3 px-2 text-center'>
                                                        <div className={(userinfo.languages.includes(Number(item?.id))) ? 'bg_orgn  pos-rel' : 'bg_orgn_no pos-rel'}>
                                                            <input onClick={handleChange} value={(item?.id)} name={item?.name} type='checkbox' className='chk-bx' />
                                                            <img className='ico_nochk' src={`${item?.thumbnail_image}`} alt="CHECK BOX ICON" />
                                                        </div>
                                                        <p className='txt-all mb-0 mt-2'>{item?.name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    null}
                                <div className='col-lg-12 mt-5'>
                                    <p className='labl'>HOW OFTEN</p>
                                </div>
                                {makeBookingService?.services_type?.map((item, index) => {
                                    // console.log(item, "servicetypeservicetypeservicetypeservicetype");
                                    return (
                                        <div key={index} className='col-lg-3 mt-2 px-2 text-center'>
                                            {/* className={(userinfo1.often.includes(item.value)) ? 'bg_orgn  pos-rel pad-sml' : 'bg_orgn_no pos-rel pad-sml'} */}
                                            <div className={(howOften == item.id) ? 'bg_orgn  pos-rel pad-sml' : 'bg_orgn_no pos-rel pad-sml'}>
                                                <input onChange={() => { HowOftenTime(item.id) }} value={item.id} type='checkbox' className='chk-bx' />
                                                <span className=''>{item.name}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='col-lg-12 mt-5'>
                                    <p className='paymnt_head'>ADDRESS <small className='address_small_makebooking'>(Select only suggestable address options)*</small></p>
                                </div>
                                <div className='col-lg-8 my-3'>
                                    <ReactGoogleAutocomplete
                                        options={{
                                            types: ["establishment"],
                                        }}
                                        className='inpt-fld'
                                        apiKey="AIzaSyDUgQwYkR8qiwXZt2V-Hf4uIFTAxW4STxY"
                                        onPlaceSelected={(place) => { GoogleGetLatLong(place) }}
                                    />
                                </div>
                                <div className='col-lg-4 my-3'>
                                    <input onChange={(e) => { setZipCode(e.target.value) }}
                                        placeholder='Zip Code' type='number' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 mt-5'>
                                    <p className='paymnt_head mb-0'>APPLY DISCOUNT COUPON</p>
                                </div>
                                <div className='col-lg-8 my-3'>
                                    <input
                                        value={discountCoupon}
                                        onChange={(e) => { setDiscountCoupon(e.target.value) }}
                                        placeholder='Discount Code' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <button onClick={checkCouponFunc} class="btn btn-light bt-website-orange">Apply Coupon</button>
                                </div>
                                <div className='col-lg-12 my-3 mb-5'>
                                    <button onClick={ProceedToCheckOut} class="btn btn-light bt-website-orange">Proceed To Next Step</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MakeBooking;