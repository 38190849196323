import React, { useEffect, useState } from "react";
import Header from "../../ReusableComponents/Header/Header";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import '../../Assests/Css/SmallPages.css';
import Footer from "../../ReusableComponents/Footer/Footer";
import AOS from "aos";
import axios from "axios";
import { showLoader } from "../../Services/Loader";
import { FaqsApiUrl, headers } from "../../Services/ApisHelper";


const Faq = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [state, setState] = useState(null);

    const FaqApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(FaqsApiUrl, headers);
            // console.log(data.response.data);
            setState(data.response.data);
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }


    let arr = [
        {
            id: 1,
            heading: "Q1: How do I book my cleaning?",
            inneeText: [
                { text: `A: Chargebacks: Due to the increase in fraudulent chargebacks we've experienced throughout the pandemic, we've hired a collections agency to put all fraudulent chargebacks into collections. All accounts in collections will also receive a 10% late fee every month. As well as, all *NEW* customers will be charged upon booking no matter how far out you book.` },
                { text: `How-To-Book` },
                {
                    text: `
                When booking a move-in/Move out cleaning, you MUST select the move-in move-out package. If you do not, we are not liable for it to be a completed move-out/move-in and most likely will not confirm the booking. If your home is more significant than 2 beds 2 baths, you are REQUIRED to select deep cleaning. The deep cleaning allocates the necessary time to clean these large homes properly. It is unfair to expect our staff members to clean your entire home in less time.
                ` },
                { text: `How to determine if your home is "Slightly Dirty, Pretty Dirty, or Very Dirty."` },
                { text: `If your home hasn't been professionally cleaned within the last year, you should select "Very Dirty."` },
                { text: `If your home has been cleaned within 6 months, you should select "Pretty Dirty."` },
                { text: `If your home has been professionally cleaned within the last 3 months, you should select" Slightly Dirty."` }
            ]
        },
        {
            id: 2,
            heading: `Q2: What is included in regular cleaning?`,
            inneeText: [
                { text: `A: Do's :` },
                { text: `•	Kitchen (clean sink out, countertops, cabinet faces upper and lower, stovetop)` },
                { text: `•	All exterior surfaces in all rooms` },
                { text: `•	Floors are mopped and vacuumed` },
                { text: `•	Bathroom (complete toilet clean, shower, handles, tub, drain)` },
                { text: `•	Bedroom  shelving, nightstands, bed frames, tidy up bed)` },
                { text: `•	All window sills` },
                { text: `& Dont's:` },
                { text: `•	All Extras are not included (must be selected for an additional cost)` },
                {
                    text: `•	The extra time in deep cleaning allows for more detailed work in the above list.`
                }
            ]
        },
        {
            id: 3,
            heading: "Q3: What is included in a deep cleaning?",
            inneeText: [
                { text: "A: Do's:" },
                { text: "•	Everything included in a regular clean +" },
                { text: "•	Baseboards" },
                { text: "•	Light switches & Door handles" },
                { text: "•	Detailed Dusting" },
                { text: "•	Back Splashes and Faucet Fixtures are polished" },
                { text: "•	Light organization of all rooms (for detailed organization, select add-on)" },
                { text: "•	Extra hours included for high-detail areas" },
                { text: "Don'ts:" },
                { text: "•	All Extras are not included (must be selected for an additional cost)" }
            ]
        },
        {
            id: 4,
            heading: "Q4: What is included in a move-out or move-in cleaning?",
            inneeText: [
                { text: "A: When booking a Move Out or Move In clean, you can expect an all-inclusive service. This clean is intended to turn the home someone once lived in into its original state and ready for new homeowners. It is our most detailed package and includes 4 of the 7 extra services we offer, which you can add to any level of service. These extras include inside the oven, fridge, cabinets, and windows." },
            ]
        },
        {
            id: 5,
            heading: "Q5: Which services do you recommend for first-time clients?",
            inneeText: [
                { text: "A: A deep clean is recommended for all first-time cleaning by MAPLE. It allows us extra time to get all the missed areas during regular cleanings. After the initial deep clean, most customers switch to a standard 2-hour clean." },
            ]
        },
        {
            id: 6,
            heading: "Q6: What about cancellation and refunds?",
            inneeText: [
                { text: "A: If you cancel before 48 hours, you will receive a full refund of the payment information on file." },
                { text: "If you cancel within 48 hours of your booking, your booking is credited to your account to use whenever you'd like." },
                { text: "If you cancel within 24 hours, it is a non-refundable full charge." },
            ]
        },
        {
            id: 7,
            heading: "Q7: What are your hours of operation?",
            inneeText: [
                { text: "A: Our hours of operation are:" },
                { text: "Monday – Friday: 8am-9pm" },
                { text: "Saturday: 9am-6pm" },
                { text: "Sunday: 9am-5pm" },
            ]
        },
        {
            id: 8,
            heading: "Q8: Do you work on weekends?",
            inneeText: [
                { text: "A: To maintain dependability, we have closed weekend hours and will only serve customers during the weekdays." },

            ]
        },
        {
            id: 9,
            heading: "Q9: Is there a rescheduling fee?",
            inneeText: [
                { text: "A: We do not charge for rescheduling as long as it is done before 48 hours of your original booking. Please see our Cancellation & Refund FAQ for further information." },
            ]
        },
    ]

    useEffect(() => {
        FaqApi();
        AOS.init();
        AOS.refresh();
        window.scroll(0,0);
    }, []);

    return (
        <div className="Faq">
            <Header />
            <div className="faq_content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="main_faq_head">Got A Few Questions?</p>
                            {state?.map((item) => {
                                return (
                                    <Accordion key={item.id} className="acrdn_main my-3" expanded={expanded === `${item.id}`} onChange={handleChange(`${item.id}`)}>
                                        <AccordionSummary
                                            className="acrdn_summry"
                                            expandIcon={(expanded === `${item?.id}`) ? <RemoveIcon className="text-dark" /> :
                                                <ExpandMoreIcon className="text-dark" />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <p className="faq_acrdn_heading">{item.question}</p>
                                        </AccordionSummary>
                                        <AccordionDetails className="acrdn_details">
                                            <div dangerouslySetInnerHTML={{
                                                __html: item?.description
                                            }}></div>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Faq;