import React, { useState } from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/MakeBooking.css';
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import BlindsClosedIcon from '@mui/icons-material/BlindsClosed';
import StripeForm from '../../ReusableComponents/Stripe/StripeForm';

const NewBooking = () => {

    return (
        <div className='MakeBooking'>
            <Header />
            <div className='MakeBooking_content'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='first_heading'>New Booking</p>
                            <p className='subMain_heading '>Step 01</p>
                        </div>
                        <div className='col-lg-7'>
                            <div className='row'>
                                <div className='col-lg-6 px-1'>
                                    <input placeholder="First name" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-6  px-2'>
                                    <input placeholder="Last name" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-6 mt-3  px-2'>
                                    <input placeholder="Your email" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-12 mt-5  px-2'>
                                    <p className='subMain_heading '>Step 02</p>
                                </div>
                                <div className='col-lg-12 my-2  px-2'>
                                    <input placeholder="Address*" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-6 my-2  px-2'>
                                    <input placeholder="City*" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-3 my-2  px-2'>
                                    <select className='slct-fld'>
                                        <option></option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-3 my-2  px-2'>
                                    <input placeholder="Zip Code" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-6 my-2 px-2' >
                                    <input placeholder="Phone" type="text" className="inpt-fld" />
                                </div>

                                <div className='col-lg-12 mt-5 px-2'>
                                    <p className='subMain_heading '>Step 03</p>
                                </div>
                                <div className='col-lg-12 mt-5 mb-2 px-2'>
                                    <select className='slct-fld'>
                                        <option>One time</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-3 my-2 px-2' >
                                    <input placeholder="Date" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-3 my-2 px-2' >
                                    <input placeholder="Time" type="text" className="inpt-fld" />
                                </div>
                                <div className='col-lg-10 my-2 px-2'>
                                    <select className='slct-fld'>
                                        <option>Select type of service</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-4 my-2 px-2'>
                                    <select className='slct-fld'>
                                        <option>1 Bedroom</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-12 mb-4'></div>
                                <div className='col-lg-6 mt-4 my-2 px-2' >
                                    <select className='slct-fld'>
                                        <option>Bathroom</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-6'>

                                </div>
                                <div className='col-lg-6 my-2 px-2' >
                                    <select className='slct-fld'>
                                        <option>Kitchen</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-6'>

                                </div>
                                <div className='col-lg-6 my-2 px-2' >
                                    <select className='slct-fld'>
                                        <option>Slightly Dirty</option>
                                        <option></option>
                                        <option></option>
                                    </select>
                                </div>
                                <div className='col-lg-12 my-2 px-2' >
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside the Oven</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Walls</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside Windows</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside Cabinets</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside the Fridge</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Laundry</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Organization</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside Dishwasher</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside Garage</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Microwave</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Blinds</span>
                                    </div>
                                    <div>
                                        <input type='checkbox' className='my-2 mr-4' /> <span className='chk-txt'>Inside Washer/Drye</span>
                                    </div>
                                </div>

                                <div className='col-lg-12 text-right mt-5'>
                                    <button className="btn btn-light bt-website-orange ">Add Service</button>
                                </div>
                                <div className='col-lg-6 my-2 px-2' >
                                    <input placeholder="Discount Card" type="text" className="inpt-fld" />
                                </div>
                                
                                <div className='col-lg-12 mt-5'>
                                    <p className='labl'>ADDRESS</p>
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <input placeholder='Address*' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input placeholder='Zip Code' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input placeholder='Phone' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 mt-5'>
                                    <p className='paymnt_head'>Payment method</p>
                                </div>
                                <div className='col-lg-6 my-3'>
                                    <input placeholder='Discount Code' type='text' className='inpt-fld' />
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <p className='txt-all'>Booking will not be saved unless you have up-to-date <span className='crdt-crd'>Credit Card</span> in your Billing Info.</p>
                                </div>
                                <div className='col-lg-12 my-3'>
                                    <StripeForm />
                                </div>
                                <div className='col-lg-12 mb-5'>
                                    <input type="checkbox" className='mt-1 mr-3' />
                                    <span className='txt-all'>I agree to the Terms of Service and Privacy Policy.</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 pl-5'>
                            <div className='booking_detials_box mt-0'>
                                <p className='booksmry'>Booking Summary</p>
                                <div className='row'>
                                    <div className='col-lg-7'>
                                        <p className='txt-all pl-3' >1 Bedroom</p>
                                        <ul>
                                            <li className='txt-all lst-crcl mb-1'>
                                                1 Bedroom
                                            </li>
                                            <li className='txt-all lst-crcl mb-1'>
                                                1 Kitchen
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-lg-5 text-right pr-4'>
                                        <p className='txt-all'>$80.00</p>
                                        <p className='txt-all mb-1'>$0.00</p>
                                        <p className='txt-all mb-1'>$80.00</p>
                                    </div>

                                    <div className='col-lg-12 '>
                                        <p className='Booking_TOT'>Booking Total Amount</p>
                                    </div>
                                    <div className='col-lg-7'>
                                        <p className='txt-all pl-3 mb-1' >Choose service date</p>
                                        <p className='txt-all pl-3' >One Time</p>
                                    </div>
                                    <div className='col-lg-5 text-right  pr-4'>
                                        <p className='txt-all mb-0' >$80.00</p>
                                        <p className='txt-all' >$19.00</p>
                                    </div>
                                    <div className='col-lg-12 text-right'>
                                        <p className='orng-lrg-txt pr-3'>$99.00</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NewBooking;