import React, { useEffect, useState } from 'react';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/SmallPages.css';
import { hm1, hm2, hm3, hm4 } from '../../MediaConstants/Index';
import Footer from '../../ReusableComponents/Footer/Footer';
import AOS from "aos";
import { showLoader } from '../../Services/Loader';
import { AboutUsApiUrl, headers } from '../../Services/ApisHelper';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const About = () => {

    const [state, setState] = useState();
    const navigate = useNavigate();
    const AboutUsApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(AboutUsApiUrl, headers)
            // console.log(data.response.data);
            setState(data.response.data);
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    useEffect(() => {
        AboutUsApi();
        AOS.init();
        AOS.refresh();
        window.scroll(0, 0);
    }, []);


    return (
        <div className='AboutPage'>
            <Header />
            <div className='Homepage mb-ftr'>
                <div className="home_first_section">
                    <div className="content ">
                        <div data-aos-duration="2000" data-aos="fade-right" className="pl-home-top pr-4">
                            <p className="mb-4 txt-shadd">{state?.[0]?.title}</p>
                            <div className='txt-all abt-txt' dangerouslySetInnerHTML={{
                                __html: state?.[0]?.content
                            }}>
                            </div>
                            <div>
                                <div className='row wdth-115'>
                                    {state?.[0].aboutusimage.map((item, index) => {
                                        // console.log('zzzzzzzzzzzzzzzzzzzzzzzzz',item?.image);
                                        return (
                                            <div key={index + 1000} className='col-lg-3 px-1'>
                                                <img data-aos="fade-up" data-aos-duration="2000" className='w-100 home_img_four_last' src={item?.image} />
                                            </div>
                                        )
                                    })}

                                    {/* <div className='col-lg-3 px-1'>
                                        <img data-aos="fade-down" data-aos-duration="2000" className='w-100' src={hm2} />
                                    </div>
                                    <div className='col-lg-3 px-1'>
                                        <img data-aos="fade-up" data-aos-duration="2000" className='w-100' src={hm3} />
                                    </div>
                                    <div className='col-lg-3 px-1'>
                                        <img data-aos="fade-down" data-aos-duration="2000" className='w-100' src={hm4} />
                                    </div> */}
                                </div>
                            </div>
                            <button onClick={() => { navigate('/makebooking') }} className="btn btn-light bt-website-orange mr-5 ml-0 pos-rel-lft">Book Now</button>
                        </div>
                    </div>
                    <div data-aos="fade-left" style={{ backgroundImage: `url("${state?.[0]?.image}")` }} className="image-bg-content about-cnt"></div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default About;