import React from "react";
import { useSelector } from "react-redux";

const ViewProfile = (props) => {

    const selector = useSelector((state) => state.Auth);
    // console.log(selector, 'selectorselectorselector');
    return (
        <div className="ViewProfile">
            <div className="row">
                <div className="col-lg-6">
                    <p className="profile_head mt-0">Profile</p>
                    <p className="User_details">User details</p>
                </div>
                <div className="col-lg-6 text-right">
                    <p onClick={() => { props.changeProfileTab(2) }} className="edit">Edit Profile</p>
                </div>
            </div>
            <div className="detail_box mb-5">
                <div className="row">
                    <div className="col-lg-4">
                        <p className="p-head">Name</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.first_name} {selector?.user?.last_name}</p>
                    </div>
                    {/* <div className="col-lg-4 text-right">

                                    </div> */}
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Contact Number</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.phone_no} </p>
                    </div>
                    {/* <div className="col-lg-4 text-right">
                                        <p className="edit">Edit</p>
                                    </div> */}
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">User Name</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.username}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Zip Code</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.zip_code}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                  
                    <div className="col-lg-4">
                        <p className="p-head">City</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.city}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Country</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.country}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Street</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.street}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">State</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.state}</p>
                    </div>
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Address</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">{selector?.user?.address}</p>
                    </div>
                    {/* <div className="col-lg-4 text-right">
                                        <p className="edit">Edit</p>
                                    </div> */}
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">Password</p>
                    </div>
                    <div className="col-lg-8">
                        <p className="p-detail">***********</p>
                    </div>
                    {/* <div className="col-lg-4 text-right">
                                        <p className="edit">Edit Password</p>
                                    </div> */}
                    <div className="col-lg-12 text-center">
                        <div className="bordr-btm-lightgrey">

                        </div>
                    </div>
                    <div className="col-lg-4">
                        <p className="p-head">E-mail</p>
                    </div>
                    <div className="col-lg-8 ">
                        <p className="p-detail">{selector?.user?.email}</p>
                    </div>
                    {/* <div className="col-lg-4 text-right">
                                        <p className="edit">Edit</p>
                                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ViewProfile;