import React, { useEffect, useState } from "react";
import Footer from "../../ReusableComponents/Footer/Footer";
import Header from "../../ReusableComponents/Header/Header";
import '../../Assests/Css/Home.css';
import { calendar_check, chair_pers, clean, comp1, comp2, comp3, comp4, hm1, hm2, hm3, hm4, home_img2, homtwo1, homtwo2, homtwo3, homtwo4, house_line } from "../../MediaConstants/Index";
import TestimonialSlider from "./TestimonialSlider";
import AOS from "aos";
import "aos/dist/aos.css";
import { headers, PartnersUrl, SiteSetting } from "../../Services/ApisHelper";
import axios from "axios";
import { showLoader } from "../../Services/Loader";
import video_maple from '../../Assests/video/maplevid.mp4';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { InfoAfterLoginApi, LogoutApiDelToken } from "../../Redux/Auth/Auth_Action";

const Home = () => {
    const navigate = useNavigate();
    const new_date = new Date().toISOString().slice(0, 10);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.Auth);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [proudPartners, setProudPartners] = useState(null);
    const [state, setState] = useState(null);

    const getSiteSettings = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(SiteSetting, headers);
            // console.log(data.response.data[0], 'hhhhhhhhhhhhhhhhhhhhhhhhhhh');
            setState(data?.response?.data[0]);
            // localStorage.setItem("siteData", JSON.stringify(data?.response?.data[0]));
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    const GetPartnersApi = async () => {
        showLoader("flex");
        try {
            const { data } = await axios.get(PartnersUrl, headers);
            setProudPartners(data?.response?.data);
            // console.log(data?.response?.data, 'pppppppppppppppppppppp');
            showLoader("none");
        } catch (e) {
            console.log(e);
            showLoader("none");
        }
    }

    const MakeAppointment = () => {
        if (!time || !date) {
            toast.error("Please fill both fields");
        }
        else {
            localStorage.setItem("maple_home_time", JSON.stringify(time));
            localStorage.setItem("maple_home_date", JSON.stringify(date));
            navigate('/makebooking');
        }
    }


    const InfoAfterLogin = () => {
        if (!selector.token) {
            // console.log(selector.token, '6666666666666666666666666666');
        } else {
            // console.log("outside token");
            dispatch(InfoAfterLoginApi(selector.token))
                .then((res) => {
                    // console.log(res, "rezzzzzzzzzzzzzzzzzzzzzzzzz");
                })
                .catch((err) => {
                    console.log(err, 'zzzzzzzzzzzzzzzzzzzzzzzzz');
                    dispatch({
                        type: "DEL_TOKEN"
                    })
                })
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        GetPartnersApi();
        getSiteSettings();
        InfoAfterLogin();
        window.scroll(0, 0);
    }, []);

    return (
        <div className="Homepage">
            <Header />
            <div className="home_first_section">
                <div data-aos="fade-right" className="content">
                    <div className="pl-home-top pr-3">
                        <p className="mb-0 ">{JSON?.parse(localStorage?.getItem("siteData"))?.section_1_title}</p>
                        <div dangerouslySetInnerHTML={{ __html: JSON?.parse(localStorage?.getItem("siteData"))?.section_1_description }} className="home_sml_txt txt-all"></div>
                        <button onClick={() => { navigate('/makebooking') }} className="btn btn-light bt-website-orange ">Book now</button>
                    </div>
                </div>
                <div data-aos="fade-left" style={{ backgroundImage: `url("${state?.section_one_image}")` }} className="image-bg-content bg_img_hmm"></div>
            </div>
            <div className="make_appointment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 pr-5 pr-res-auto">
                            <div data-aos="fade-right" className="make_appointment_box">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <p className="select-catt">Select Date</p>
                                        <input min={new_date} type='date' value={date} onChange={(e) => { setDate(e.target.value) }} className="HOME_slct_fld" />
                                    </div>
                                    <div className="col-lg-4">
                                        <p className="select-catt">Select Time</p>
                                        <input type='time' value={time} onChange={(e) => { setTime(e.target.value) }} className="HOME_slct_fld" />
                                    </div>
                                    <div className="col-lg-4 mt-2">
                                        <button onClick={MakeAppointment} className="btn btn-light bt-website-orange mt-2rem w-100 mr-3 py-3">Make Appointment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="we_are_best_sol">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pr-5 pr-res-auto">
                            <div className="brdr_top_80px">

                            </div>
                            <p className="we_are_best_sol_P" >
                                {state?.section_2_title}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: state?.section_2_description }} className="txt-all mt-4"></div>
                            <button onClick={() => { navigate('/about') }} className="btn btn-light bt-website-orange mt-3 ">
                                Read More
                            </button>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src={state?.step_one_image_section2} alt="service desciption" />
                                    <div dangerouslySetInnerHTML={{ __html: state?.step_2_desc_section2 }} ></div>
                                </div>
                                <div className="col-lg-6">
                                    <img src={state?.step_two_image_section2} alt="service desciption" />
                                    <div dangerouslySetInnerHTML={{ __html: state?.step_2_desc_section2 }} ></div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <img src={state?.step_three_image_section2} alt="service desciption" />
                                    <div dangerouslySetInnerHTML={{ __html: state?.step_3_desc_section2 }} ></div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                    <img src={state?.step_four_image_section2} alt="service desciption" />
                                    <div dangerouslySetInnerHTML={{ __html: state?.step_4_desc_section2 }} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5 mt-res-auto">
                        <div data-aos="fade-up" className="col-lg-6 mt-5">
                            <video className="video_home" controls>
                                <source src={video_maple} type="video/mp4" />
                            </video>
                        </div>
                        <div data-aos="fade-down" className="col-lg-6 align-center mt-5">
                            <div className="brdr_top_80px"></div>
                            <p className="we_are_best_sol_P">{state?.section_3_title}</p>
                            <div dangerouslySetInnerHTML={{ __html: state?.section_3_description }} className="txt-all "></div>
                            <button onClick={() => { navigate('/about') }} className="btn btn-light bt-website-orange mt-3 ">Read More</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="orange_div_home">
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-down-left" className="col-lg-12 mb-4">
                            <p className="how_heading mb-1">How it work</p>
                            <p style={{ color: "#fffffff2", fontSize: "14px" }} className="txt-all text-light-home">{state?.how_it_works}</p>
                        </div>
                        <div data-aos="fade-down-left" className="col-lg-4 pr-5">
                            <img src={state?.step_one_image} alt="step 1" />
                            <p className="select_size mb-4 mt-3">{state?.step_1_description}</p>
                            <p className="select_sizez mt-5">STEP 1</p>
                        </div>
                        <div data-aos="fade-down-left" className="col-lg-4 pr-5">
                            <img src={state?.step_two_image} alt="step 2" />
                            <p className="select_size mb-4 mt-3">{state?.step_2_description}</p>
                            <p className="select_sizez">STEP 2</p>
                        </div>
                        <div data-aos="fade-down-left" className="col-lg-4 pr-5">
                            <img src={state?.step_three_image} alt="step 3" />
                            <p className="select_size mb-4 mt-3">{state?.step_3_description}</p>
                            <p className="select_sizez">STEP 3</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="client_says">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-5 mt-5">
                            <div data-aos="fade-right" className="home_square_bg">
                                <p className="heading_home">
                                    <span className="top_brdr_heading">Sat</span>isfied Customers
                                </p>
                                <p className="txt-all"><b>Satisfied customers across Washington</b></p>
                                <p className="txt-all">Over 500+ reviews on Yelp, Over 300+ Reviews on Google</p>
                            </div>
                        </div>
                        <div data-aos="fade-left" className="offset-lg-1 col-lg-6 mt-5">
                            <TestimonialSlider />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 mt-5">
                            <div data-aos="flip-down" className="blk-bx-numbrz mt-5">
                                <div className="row">
                                    <div className="col-lg-3 text-center ">
                                        <p className="numbr_bigg">
                                            {state?.subscription}
                                        </p>
                                        <p className="txt-all mb-0 text-white txt-pppp">Subscription</p>
                                    </div>
                                    <div className="col-lg-3 text-center">
                                        <p className="numbr_bigg">
                                            {state?.city_offices}
                                        </p>
                                        <p className="txt-all mb-0 text-white txt-pppp">Cities Office</p>
                                    </div>
                                    <div className="col-lg-3 text-center">
                                        <p className="numbr_bigg">{state?.worker}</p>
                                        <p className="txt-all mb-0 text-white txt-pppp">Worker</p>
                                    </div>
                                    <div className="col-lg-3 text-center">
                                        <p className="numbr_bigg">{state?.happy_clients}</p>
                                        <p className="txt-all mb-0 text-white txt-pppp">Happy Clients</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="proud_partner text-center mb-5 mt-5">
                <p className="proud_prtnr_heading mb-4">Proud Patners</p>
                <div className="flx-prtnr">
                    {proudPartners?.map((item) => {
                        return (
                            <div key={item?.id}>
                                <img data-aos="zoom-in-up " width={150} src={item?.image} alt="logo1" />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="keep_clean my-cus-home">
                <div className="container">
                    <div className="row">
                        <div data-aos="fade-top" className="col-lg-5">
                            <div className="brdr_top_80px"></div>
                            <p className="heading_home">
                                {state?.section_4_title}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: state?.section_4_description }} className="txt-all"></div>
                            <button onClick={() => { navigate('/makebooking') }} className="btn btn-light bt-website-orange mt-4">Book Us</button>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-4 mt-3 px-2">
                                    <img data-aos="flip-up" data-aos-duration="2500" className="w-100 home_img_four_last" src={state?.pic_one} alt="clean area" />
                                </div>
                                <div className="col-lg-4 mt-3 px-2">
                                    <img data-aos="flip-up" data-aos-duration="2500" className="w-100 home_img_four_last" src={state?.pic_two} alt="clean area" />
                                </div>
                                <div className="col-lg-4 px-2">

                                </div>
                                <div className="col-lg-4 px-2">

                                </div>
                                <div className="col-lg-4 mt-3 px-2">
                                    <img data-aos="flip-down" data-aos-duration="2500" className="w-100 home_img_four_last" src={state?.pic_three} alt="clean area" />
                                </div>
                                <div className="col-lg-4 mt-3 px-2">
                                    <img data-aos="flip-down" data-aos-duration="2500" className="w-100 home_img_four_last" src={state?.pic_four} alt="clean area" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;