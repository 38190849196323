import React from 'react';
import '../../Assests/Css/Auth.css';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { SignupApi } from '../../Redux/Auth/Auth_Action';


const Registration2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [state, setState] = useState({
        first_name: null,
        last_name: null,
        email: null,
        phone_no: null,
        zip_code: null,
        street: null,
        state: null,
        address: null,
        city: null,
        country: null,
        password: null,
        password_confirmation: null,
        code: null,
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const SubmitData = (e) => {
        e.preventDefault();
        if (state.password !== state.password_confirmation) {
            toast.error("password and confirm password not matched")
        }
        else {
            console.log(state, '000000000000000000000000000');
            Registration();
        }
    }

    const Registration = () => {
        let data = {
            first_name: state.first_name || "",
            last_name: state.last_name || "",
            email: state.email || "",
            phone_no: state.phone_no || "",
            zip_code: state.zip_code || "",
            street: state.street || "",
            state: state.state || "",
            address: state.address || "",
            city: state.city || "",
            country: state.country || "",
            password: state.password || "",
            password_confirmation: state.password_confirmation || "",
            code: state.code || "null",
        }

        dispatch(SignupApi(data))
            .then((res) => {
                // console.log(res, 'zzzzzzzzzzz');
                setTimeout(() => {
                    navigate('/login');
                }, 1500);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <div className='Auth2'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <p className='auth_heading'>Call Us Today, <br />
                            For The Same Day High <br />
                            Standard Cleaning
                        </p>
                    </div>
                    <div className='col-lg-5'>
                        <div className="login-contnr">
                            <div className='login_box'>
                                <form onSubmit={SubmitData}>
                                    <div className='row'>
                                        <div className='col-lg-12 text-center'>
                                            <p className='sgn-bx-hd mb-0 '>SIGN UP</p>
                                            {/* <small className='clr-gry text-black'>Et netus et malesuada fames. Dolor magna eget est lorem ipsum.</small> */}
                                            <p className='alrdd'>Already a memeber ? <span onClick={() => { navigate('/login') }} className='orng-txt cursor_pointer'>Sign In</span>
                                            </p>
                                        </div>
                                        <div className='col-lg-6 px-1 mt-rez-signup'>
                                            <input
                                                value={state.first_name}
                                                name='first_name'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='First Name' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-6 px-1 mt-rez-signup'>
                                            <input
                                                value={state.last_name}
                                                name='last_name'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='Last Name' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-6 px-1 mt-2'>
                                            <input
                                                value={state.email}
                                                name='email'
                                                onChange={InputVal}
                                                required
                                                type='email' placeholder='Email' className='inpt-fld' />
                                        </div>
                                        {/* <div className='col-lg-6 px-2 mt-3'>
                                                <input type='text' placeholder='User Name' className='inpt-fld' />
                                            </div> */}
                                        <div className='col-lg-6 px-1 mt-2'>
                                            <input
                                                value={state.phone_no}
                                                name='phone_no'
                                                onChange={InputVal}
                                                required
                                                type='number' placeholder='Phone Number' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.zip_code}
                                                name='zip_code'
                                                onChange={InputVal}
                                                required
                                                type='number' placeholder='Zip Code' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.street}
                                                name='street'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='Street' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.state}
                                                name='state'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='State' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-12 px-1 mt-2'>
                                            <input
                                                value={state.address}
                                                name='address'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='Address' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.city}
                                                name='city'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='City' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.country}
                                                name='country'
                                                onChange={InputVal}
                                                required
                                                type='text' placeholder='Country' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-4 px-1 mt-2'>
                                            <input
                                                value={state.code}
                                                name='code'
                                                onChange={InputVal}

                                                type='text' placeholder='Referal Code' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-6 px-1 mt-2'>
                                            <input
                                                minLength={8}
                                                value={state.password}
                                                name='password'
                                                onChange={InputVal}
                                                required
                                                type='password' placeholder='Password' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-6 px-1 mt-2'>
                                            <input
                                                minLength={8}
                                                value={state.password_confirmation}
                                                name='password_confirmation'
                                                onChange={InputVal}
                                                required
                                                type='password' placeholder='Confirm Password' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <button type='submit' className="btn btn-light bt-website-orange w-100">
                                                Sign Up
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration2;