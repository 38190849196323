import React, { useState } from 'react';
import '../../Assests/Css/Auth.css';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useNavigate } from 'react-router';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { CoPresentOutlined } from '@mui/icons-material';
import { LoginApi } from '../../Redux/Auth/Auth_Action';
import { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import { toast } from 'react-toastify';

const LoginTwo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState(null);

    const [state, setState] = useState({
        email: null,
        password: null,
    });

    const InputVal = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const Signin = (e) => {
        e.preventDefault();
        let data = {
            email: state.email,
            password: state.password,
            facebook_id: null,
            name: name,
            google_id: null,
        }
        LoginApiFunc(data);
    }

    // console.log(state, 'state from login ');
    // google success
    const onSuccessGoogle = (response) => {
        console.log(response)
        let data = {
            email: response.profileObj.email,
            password: 0,
            facebook_id: 0,
            name: response.profileObj.name,
            google_id: response.profileObj.googleId,
        }
        LoginApiFunc(data);
        // console.log(response, 'successfull google response');
    }

    // facebook success
    const responseFacebook = (response) => {
        console.log(response);
        let data = {
            email: response.email,
            password: 0,
            facebook_id: response.id,
            name: response.name,
            google_id: 0,
        }
        LoginApiFunc(data);
    }

    // google fail
    const onFailGoogle = (response) => {
        console.log(response, 'unsucessfull google response');
        toast.error(response);
    }

    // facebook fail
    const componentClicked = (response) => {
        console.log(response, 'unsucessfull facebook');
        toast.error(response);
    }

    const LoginApiFunc = (data) => {
        // console.log(data, '...................');
        dispatch(LoginApi(data))
            .then((res) => {
                navigate('/');
                // console.log(res, '..................');
            })
            .catch((e) => {
                let message = typeof e.response !== "undefined" ? e.response.data.message : e.message;
                console.log(message, '..................');
            })
    }

    return (
        <div className='Auth2'>
            <div className='container'>

                <div className='row'>
                    <div className='col-lg-7'>
                        <p className='auth_heading'>Call Us Today, <br />
                            For The Same Day High <br />
                            Standard Cleaning
                        </p>
                    </div>
                    <div className='col-lg-5 '>
                        <div className="login-contnr">
                            <div className='login_box '>
                                <form onSubmit={Signin}>
                                    <div className='row'>
                                        <div className='col-lg-12 text-center'>
                                            <p className='sgn-bx-hd mb-0 '>SIGN IN</p>
                                            {/* <small className='clr-gry'>Et netus et malesuada fames. Dolor magna eget est lorem ipsum.</small> */}
                                            <p className='alrdd'>Not a memeber ? <span onClick={() => { navigate('/signup') }} className='orng-txt cursor_pointer'>Sign Up</span></p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <input
                                                value={state.email}
                                                name="email"
                                                onChange={InputVal}
                                                type='text' placeholder='Email' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-12 mt-2'>
                                            <input
                                                value={state.password}
                                                name="password"
                                                onChange={InputVal}
                                                type='password' placeholder='Password' className='inpt-fld' />
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <button type="submit" className="btn btn-light bt-website-orange w-100">
                                                Sign In
                                            </button>
                                        </div>
                                        <div className='col-lg-12 text-center'>
                                            <p className='alrdd mb-1 mt-2'> <span onClick={() => { navigate('/forget-password') }} className='orng-txt cursor_pointer'>Forgot password?</span></p>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='line-main'>
                                                <div className='line1'>

                                                </div>
                                                <div className='or'>
                                                    OR
                                                </div>
                                                <div className='line2'>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <GoogleLogin
                                                className='google_button_custom'
                                                // clientId="1020716706874-2p7k22n63mpr4o7djf0nh6kqtbsu882a.apps.googleusercontent.com"
                                                clientId="1020716706874-2p7k22n63mpr4o7djf0nh6kqtbsu882a"
                                                buttonText="Sign in with Google"
                                                onSuccess={onSuccessGoogle}
                                                onFailure={onFailGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            // isSignedIn={true}
                                            />
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <div id="signInDiv"></div>
                                            {/* <button className='btn btn-light bt-goog'><GoogleIcon className='mr-3' />Sign In with Google</button> */}
                                        </div>
                                        <div className='col-lg-12 mt-2'>
                                            {/* <button className='btn btn-light bt-goog'><FacebookIcon className='mr-3' />Sign In with Facebook</button> */}
                                            <FacebookLogin
                                                
                                                // appId="887573788991433"
                                                appId='590998059729559'
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                onClick={componentClicked}
                                                callback={responseFacebook}
                                                cssClass="my-facebook-button-class"
                                            />
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginTwo;