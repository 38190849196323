import React, { useEffect } from 'react';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/InnerService.css';
import { inner2, inner3, prof_img_round } from '../../MediaConstants/Index';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Footer from '../../ReusableComponents/Footer/Footer';
import AOS from "aos";
import { useLocation } from 'react-router-dom';

const ServicesDetail = () => {
    const location = useLocation();
    // console.log(location);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        window.scroll(0,0);
    }, []);



    return (
        <div className='InnerService'>
            <div style={{ backgroundImage: `url("${location?.state?.cover_image}")` }} className='bg_img_div'>
                <Header />
            </div>
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-lg-12 mt-3'>
                        <div
                            dangerouslySetInnerHTML={{ __html: location?.state?.description }}
                        />
                        {/* <h1 data-aos-duration="1000" data-aos="fade-left" className='heading_one'>
                            {location?.state?.inner_page?.innherHead1}
                        </h1>
                        <b className='txt-all mb-4'>{location?.state?.inner_page?.heading1}</b>
                        <p className='txt-all mt-3'>{location?.state?.inner_page?.innertxt}</p>
                        {location?.state?.inner_page?.points?.map((item, index) => {
                            return (
                                <p className='txt-all'>{item?.li}</p>
                            )
                        })} */}
                    </div>
                    {/* <div className='col-lg-12 mt-3'>
                        <h1 data-aos-duration="1000" data-aos="fade-left" className='heading_one'>
                            {location?.state?.inner_page?.innherHead2}
                        </h1>
                        <b className='txt-all'>{location?.state?.inner_page?.heading2}</b>
                        <p className='txt-all mt-3'>{location?.state?.inner_page?.innertxt2}</p>
                        {location?.state?.inner_page?.points2?.map((item, index) => {
                            return (
                                <p key={index+100} className='txt-all'>{item?.li}</p>
                            )
                        })}
                    </div> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ServicesDetail;