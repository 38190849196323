import React from 'react';
import Footer from '../../ReusableComponents/Footer/Footer';
import Header from '../../ReusableComponents/Header/Header';
import '../../Assests/Css/Auth.css';

const ChangePassword = () => {
    return (
        <div className='Auth'>
            <Header />
            <div className='container'>
                <div className='row'>
                    <div className='offset-lg-4 col-lg-4'>
                        <div className='Auth-box'>
                            <h1 className='auth-head'>Change Password</h1>
                            <input type='text' placeholder='New Password' className="inpt-field mb-1" />
                            <input type='text' placeholder='Confirm Password' className="inpt-field mb-1 mt-3" />
                            <div className='row'>
                                <div className='col-lg-12 text-center'>
                                    <button className='btn btn-light bt-website-orange px-log mt-4'>Confirm </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ChangePassword;