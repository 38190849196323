// all type of headers
export const headers = () => {
    return {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithToken = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

export const headersWithImage = (token) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

// common types of error handling
export const responseError = (e) => {
    let data = [];
    if (e.response.data.statusCode === 401) {
        data.push({ message: e.response.data.message, color: 'warning' });
    }

    else if (e.response.data.statusCode === 409) {
        e.response.data.errors.map((val) => {
            data.push({ message: val, color: 'warning' });
        });
    }

    else if (e.response.data.statusCode === 422 || e.response.data.statusCode === 405) {
        data.push({ message: e.response.data.errors, color: 'warning' });
    }

    else if (e.response.data.statusCode === 400 && e.response.data.errors) {
        for (let key in e.response.data.errors) {
            if (e.response.data.errors.hasOwnProperty(key)) {
                data.push({
                    message: e.response.data.errors[key][0],
                    color: 'warning'
                });
            }
        }
    }

    else if (e.response.data.statusCode === 404) {
        data.push({ message: e.message, color: 'info' });
    }

    else {
        data.push({ message: e.message, color: 'danger' });
    }

    return data;
}

// site url

export const site_url = 'https://mapplebackend.developer-ourbase-camp.com/public/api';
export const AboutUsApiUrl = `${site_url}/aboutus`;
export const FaqsApiUrl = `${site_url}/faqs`;
export const NewsLetterApiUrl = `${site_url}/newsletter/store`;
export const BlogsApiUrl = `${site_url}/blogs`;
export const ContactUsUrl = `${site_url}/contactquery/store`;
export const SiteSetting = `${site_url}/site/all`;
export const JobsUrls = `${site_url}/jobs/all`;
export const CareerStoreUrl = `${site_url}/carrers/store`;
export const GetGiftCardAmountUrl = `${site_url}/giftcards/all`;
export const GetGiftCardBookingUrl = `${site_url}/giftcards/booking`;
export const CouponsCheckUrl = `${site_url}/coupons/check`;
export const CouponsCheckGiftUrl = `${site_url}/coupons/checkgiftcoupon`;
export const AllServiceUrl = `${site_url}/services`;
export const SignUpUrl = `${site_url}/auth/register`;
export const LoginUrl = `${site_url}/auth/login`;
export const LogoutUrl = `${site_url}/auth/logout`;
export const ForgotPasswordUrl = `${site_url}/auth/forgot-password`;
export const ChangePasswordUrl = `${site_url}/auth/verify-otp`;
export const ChangePasswordProfileUrl = `${site_url}/auth/change-password`;
export const UpdateProfileUrl = `${site_url}/auth/updateprofile`;
export const BookingUpcomingUrl = `${site_url}/booking/upcoming`;
export const BookingHistoryUrl = `${site_url}/booking/history`;
export const GetReferralUrl = `${site_url}/referal`;
export const SentReferralUrl = `${site_url}/referal/store`;
export const LoyalityPointsUrl = `${site_url}/userpoints`;
export const RescheduleBookingUrl = `${site_url}/booking/reschedule`;
export const BookingNext = `${site_url}/booking/nextall`;
export const PostBookingUrl = `${site_url}/booking/store`;
export const GetWorkersUrl = `${site_url}/workers`;
export const PostFeedBackUrl = `${site_url}/customerfeedback/store`;
export const InfoAfterLoginUrl = `${site_url}/auth/info`;
export const GetAllWorkersUrl = `${site_url}/workers`;

// home page api

export const PartnersUrl = `${site_url}/partners/all`;
export const TestimonialUrl = `${site_url}/testimonials/all`;

// export const NewsLetterUrl = `${site_url}/`
// apis url